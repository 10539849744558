const messages = {
  'p.login.error': '登录失败，请重新登陆',
  'p.login.welcome': '服务e速达',
  'p.login.cboluser': 'ABB客户',
  'p.login.spuser': '服务商',
  'p.linkregister': '没有账号，注册一个？',
  'p.linkforgotpwd': '忘记密码？',
  'p.login.or': '或',
  'p.errorpage.403': '访问受限',
  'p.errorpage.404': '页面不存在',
  'p.errorpage.home': '返回首页',
  'p.table.action': '操作',
  'p.table.lastupdatedby': '更新人',
  'p.table.lastupdatedtime': '更新时间',
  'p.table.remark': '备注说明',
  'p.common.attachment': '附件',
  'p.common.recording': '录音',
  'p.common.applicationDate': '提交日期',
  'p.common.company': '公司名称',
  'p.masterdata.type': '数据类型',
  'p.masterdata.text': '数据文本',
  'p.masterdata.value': '数据值',
  'p.masterdata.mappingkey': '关联值',
  'p.masterdata.mappingkey2': '关联值2',
  'p.masterdata.IsEnabled': '是否启用',
  'p.masterdata.displayindex': '显示排序',
  'p.masterdata.createtitle': '新建基础数据',
  'p.masterdata.edittitle': '编辑基础数据',
  'p.region.createtitle': '新建区域',
  'p.region.updatetitle': '编辑区域',
  'p.region.parentname': '上层节点',
  'p.region.name': '区域名称',
  'p.region.code': '区域编码',
  'p.region.isEnabled': '是否启用',
  'p.region.displayindex': '显示排序',
  'p.producttypeclassify.parentname': '上层分类',
  'p.producttypeclassify.name': '分类名称',
  'p.producttypeclassify.code': '分类编码',
  'p.producttypeclassify.isEnabled': '是否启用',
  'p.producttypeclassify.displayindex': '显示排序',
  'p.workflow.name': '流程名称',
  'p.workflow.code': '流程编码',
  'p.workflow.issyncsparrow': '同步Sparrow',
  'p.workflow.sparrowappcode': 'Sparrow App Code',
  'p.workflow.sparrowworkflowcode': 'Sparrow Workflow Code',
  'p.workflow.sparrowtoken': 'Sparrow Token',
  'p.workflow.sparrowpushtaskUrl': 'Sparrow Push Task Url',
  'p.workflow.createtitle': '新建流程',
  'p.workflow.edittitle': '编辑流程',
  'p.sparrowtemplate.workflow': '流程名称',
  'p.sparrowtemplate.sqlscript': 'SQL脚本',
  'p.sparrowtemplate.xmltemplate': 'XML模板',
  'p.action.deleteconfirmtitle': '确认要删除该数据吗？',
  'p.action.cancelconfirmtitle': '确认要取消该申请吗？',
  'p.tsrequest.area.servicerequestbaseinfotitle': '服务申请单信息',
  'p.tsrequest.area.servicerequestorinfotitle': '服务申请人信息',
  'p.tsrequest.area.tsrequestordetailinfotitle': '故障产品信息',
  'p.tsrequest.area.attachmentinfotitle': '相关附件信息',
  'p.tsrequest.area.servicerequestorserviceinfotitle': '服务申请人服务要求',
  'p.tsrequest.area.finaltechnicalproposalinfotitle': '最终技术建议',
  'p.tsrequest.area.processattachmentinfotitle': '处理相关附件信息',
  'p.tsrequest.requestno': '服务单号',
  'p.tsrequest.status': '服务状态',
  'p.tsrequest.submitter': '服务提交人',
  'p.tsrequest.submittime': '服务提交时间',
  'p.tsrequest.applicant': '服务提交人',
  'p.tsrequest.applicationdate': '服务提交日期',
  'p.tsrequest.spassignconfirm': '已接受指派',
  'p.tsrequest.bu': 'BU',
  'p.tsrequest.producthierarchy': 'Product Hierarchy',
  'p.tsrequest.province': '服务省份',
  'p.tsrequest.vendorcode': '工厂代码',
  'p.tsrequest.spname': '服务商名称',
  'p.tsrequest.isspassignconfirm': 'SPAdmin是否接受指派',
  'p.tsrequest.applicationtime': '服务申请时间',
  'p.tsrequest.tsacceptdate': '服务认领时间',
  'p.tsrequest.servicerequestor': '服务申请人',
  'p.tsrequest.servicerequestorphonenumber': '服务申请人电话',
  'p.tsrequest.servicecontactperson': '服务现场联系人',
  'p.tsrequest.servicecontactpersonphonenumber': '服务现场联系电话',
  'p.tsrequest.servicecontactpersoncompany': '服务联系人公司',
  'p.tsrequest.abbdistributor': 'ABB客户',
  'p.tsrequest.tsowner': 'TS负责人',
  'p.tsrequest.tsownerphonenumber': 'TS负责人联系电话',
  'p.tsrequest.salesoffice': '销售地城市',
  'p.tsrequest.serviceoffice': '服务地城市',
  'p.tsrequest.tsreturnStatus': '退货单状态',
  'p.tsrequest.project': '工程项目',
  'p.tsrequest.customer': '客户',
  'p.tsrequest.servicesiteaddress': '故障产品所在地',
  'p.tsrequest.acceptbyself': '服务由填写人接受',
  'p.tsrequest.changeserviceofficetitle': '修改服务地城市',
  'p.tsrequest.assigntstitle': '指派TS',
  'p.tsrequest.reassigntstitle': '重新指派TS',
  'p.tsrequest.claimtsrequesttitle': '认领服务单',
  'p.tsrequest.rejectclaimtsrequesttitle': '拒绝服务单',
  'p.tsrequest.isaccept': '接受认领',
  'p.tsrequest.rejectreason': '拒绝理由',
  'p.action.solveconfirmtitle': '确认要设置为解决吗？',
  'p.tsrequestdetail.tosolve': '解决',
  'p.tsrequestdetail.solved': '已解决',
  'p.tsrequest.completeservicetitle': '关闭服务',
  'p.tsrequest.tsreplacementrequestlist': '补换货记录',
  'p.tsrequest.onsitesupportlog': '现场服务记录',
  'p.tsrequest.onsitesupportlog.table.assigndate': '指派时间',
  'p.tsrequest.onsitesupportlog.table.spestimateddate': '预计到场时间',
  'p.tsrequest.onsitesupportlog.table.assignuser': '指派人',
  'p.tsrequest.onsitesupportlog.table.onsitesupportts': '现场服务TS',
  'p.tsrequest.onsitesupportlog.table.telphone': '联系电话',
  'p.tsrequest.onsitesupportlog.table.assignsp': '指派的SP',
  'p.tsrequest.onsitesupportlog.table.assignspe': '指派的SPE',
  'p.tsrequest.onsitesupportlog.table.spe': 'SPE',
  'p.tsrequest.onsitesupportlog.table.spetelphone': '联系电话',
  'p.tsrequest.onsitesupportlog.table.status': '状态',

  'p.tsrequest.onsitesupportlog.table.onsitesupprotno': '现场服务单号',
  'p.tsrequest.onsitesupportlog.table.role': '角色(TS/SPE)',
  'p.tsrequest.onsitesupportlog.table.applicant': '服务人员姓名',
  'p.tsrequest.onsitesupportlog.table.spname': '所属SP',
  'p.tsrequest.onsitesupportlog.table.kickoffdatetime': '作业许可提交时间',
  'p.tsrequest.onsitesupportlog.table.kickoffstatus': '作业许可审批状态',
  'p.tsrequest.onsitesupportlog.table.kickoffhealthcheck': '作业许可详情',
  'p.tsrequest.onsitesupportlog.table.closecheck': '完工检查详情',
  'p.tsrequest.onsitesupportlog.table.closedatetime': '完工时间',

  'p.action.completeconfirmtitle': '确认要设置为完成吗？',
  'p.tsrequestdetail.tocomplete': '完成',
  'p.tsrequestdetail.completed': '已完成',
  'p.tsrequestdetail.technicaladvicettitle': '技术建议',
  'p.tsrequestdetail.area.technicaladvicehistoryttitle': '历史技术建议',
  'p.tsrequestdetail.isvisabletocustomer': '发送给客户/SP',
  'p.tsrequestdetail.detailpopovertitle': '故障产品详情',
  'p.tsrequestdetail.materialno': '物料号',
  'p.tsrequestdetail.sapproducttype': 'SAP产品类型',
  'p.tsrequestdetail.producttype': '产品类型',
  'p.tsrequestdetail.mpn': 'MPN',
  'p.tsrequestdetail.vendorcode': '工厂代码',
  'p.tsrequestdetail.vendorname': '工厂名称',
  'p.tsrequestdetail.materialdescription': '产品型号',
  'p.tsrequestdetail.relatedso': '订单号',
  'p.tsrequestdetail.qty': '数量',
  'p.tsrequestdetail.servicetag': '序列号',
  'p.tsrequestdetail.MGDescription':'MG描述',
  'p.tsrequestdetail.MaterialGroup1':'物料分组',
  'p.tsrequestdetail.issuedescription': '问题描述',
  'p.tsrequestdetail.problemtype': '故障类型',
  'p.tsrequestdetail.solutiontype': '解决类别',
  'p.tsrequestdetail.servicetype': '服务类别',
  'p.tsrequestdetail.detailno': '序号',
  'p.tsrequest.additemtitle': '添加故障产品信息',
  'p.tsrequest.edititemtitle': '编辑故障产品信息',
  'p.emailtemplate.classify': '模板分类',
  'p.emailtemplate.name': '模板名称',
  'p.emailtemplate.code': '模板编码',
  'p.emailtemplate.subjecttemplate': '邮件标题',
  'p.emailtemplate.bodytemplate': '邮件内容',
  'p.emailtemplate.createtitle': '新建邮件模板',
  'p.emailtemplate.edittitle': '编辑邮件模板',
  'p.tsreplacementrequest.replacementno': '补换货单号',
  'p.tsreplacementrequest.applicationdate': '补换货申请日期',
  'p.tsreplacementrequest.requestor': '补换货申请人',
  'p.tsreplacementrequest.relatedabbdistributor': 'ABB客户',
  'p.tsreplacementrequest.responsibleoh': 'ABB订单处理专员',
  'p.tsreplacementrequest.shipfrom': '出货地点',
  'p.tsreplacementrequest.salesoffice': '销售地城市',
  'p.tsreplacementrequest.additionallocation': 'Location',
  'p.tsreplacementrequest.area.baseinfotitle': '基本信息',
  'p.tsreplacementrequest.area.receiptinfotitle': '收货信息',
  'p.tsreplacementrequest.useshippedtoparty': 'ShipToParty',
  'p.tsreplacementrequest.ncrRemark': '备注NCR',
  'p.tsreplacementrequest.shippedpartyno': 'ShiptoParty No',
  'p.tsreplacementrequest.shipToPartyInfo': 'ShipToParty信息',
  'p.tsreplacementrequest.shippedtocompany': '收货单位',
  'p.tsreplacementrequest.shippedtocity': '收货地城市',
  'p.tsreplacementrequest.postalcode': '收货邮编',
  'p.tsreplacementrequest.shippedtoperson': '收货人',
  'p.tsreplacementrequest.shippedtocontactno': '联系电话',
  'p.tsreplacementrequest.shippedtoaddress': '收货地址',
  'p.tsreplacementrequest.shipToPartyInfo':'ShipToParty信息',
  'p.tsreplacementrequest.area.ncrtitle': 'NCR信息',
  'p.tsreplacementrequest.area.detailinfotitle': '补换货产品信息',
  'p.tsreplacementrequest.materialno': '物料号',
  'p.tsreplacementrequest.description': '产品型号',
  'p.tsreplacementrequest.mpn': 'MPN',
  'p.tsreplacementrequest.listprice': '物料面价',
  'p.tsreplacementrequest.vendorcode': '工厂代码',
  'p.tsreplacementrequest.vendorname': '工厂名称',
  'p.tsreplacementrequest.servicetag': '序列号',
  'p.tsreplacementrequest.qty': '数量',
  'p.tsreplacementrequest.diviationcomments': '差异原因',
  'p.tsreplacementrequest.applyreason': '申请原因',
  'p.tsreplacementrequest.area.tsrequestinfotitle': '服务单信息',
  'p.tsreplacementrequest.tsrequestno': '服务单号',
  'p.tsreplacementrequest.tsrequestapplicationdate': '服务提交日期',
  'p.tsreplacementrequest.totalcost': '总金额',
  'p.tsreplacementrequest.selectproducttitle': '选择产品',
  'p.tsreplacementrequest.searchMaterial': '搜索物料',
  'p.tsreplacementrequest.area.tsreplacemeninfotitle': '申请单信息',
  'p.tsreplacementrequest.status': '状态',
  'p.tsreplacementrequest.applicant': '补换货申请人',
  'p.tsreplacementrequest.currency': '币种',
  'p.tsreplacementrequest.area.processinfotitle': '审批意见',
  'p.tsreplacementrequest.area.approvalloginfotitle': '审批日志信息',
  'p.tsreplacementrequest.area.vendorconfirminfotitle': '处理服务',
  'p.tsreplacementrequest.transportnumber': '货运单号',
  'p.tsreplacementrequest.transportcompany': '货运公司',
  'p.tsreplacementrequest.approvalremark': '处理意见',
  'p.tsreplacementrequest.amount': '金额',
  'p.tsreplacementrequest.cancelrequesttitle': '撤销申请',
  'p.tsreplacementrequest.lastapprover': '最后审批人',
  'p.tsreplacementrequest.lastapprovedate': '最后审批时间',
  'p.tsreplacementrequest.tsreturnStatus': '退货单状态',
  'p.tsreplacementrequest.isTureReturn': '是否正常退货',
  'p.tsreturnrequest.area.baseinfotitle': '基本信息',
  'p.tsreturnrequest.requestor': '申请人',
  'p.tsreturnrequest.transportationname': '货运公司',
  'p.tsreturnrequest.transportaionno': '货运单号',
  'p.tsreturnrequest.sendername': '寄件人',
  'p.tsreturnrequest.returnhandlingmethod': '返仓类别',
  'p.tsreturnrequest.sendercity': '寄件城市',
  'p.tsreturnrequest.shiptowarehouse': '退货目的地',
  'p.tsreturnrequest.senderphone': '寄件人电话',
  'p.tsreturnrequest.shiptoaddress': '收件人地址',
  'p.tsreturnrequest.senddate': '寄件日期',
  'p.tsreturnrequest.shiptocontactno': '收件人电话',
  'p.tsreturnrequest.shiptoperson': '收件联系人',
  'p.tsreturnrequest.area.detailinfotitle': '退货产品信息',
  'p.tsreturnrequest.area.otherinfotitle': '其他信息',
  'p.tsreturnrequest.remark': '备注',
  'p.tsreturnrequestdetail.itemno': 'No',
  'p.tsreturnrequestdetail.materialno': '物料号',
  'p.tsreturnrequestdetail.description': '产品描述',
  'p.tsreturnrequestdetail.plantname': '工厂名称',
  'p.tsreturnrequestdetail.qty': '退货数量',
  'p.tsreturnrequestdetail.devationreason': '退货差异原因',
  'p.tsreturnrequestdetail.receivedevationreason': '退货差异原因',
  'p.tsreturnrequest.tsrequestno': '服务单号',
  'p.tsreturnrequest.tsrequestapplicationdate': '服务提交日期',
  'p.tsreturnrequest.contactperson': '联系人',
  'p.tsreturnrequest.contactphone': '联系电话',
  'p.tsreturnrequest.contactaddress': '联系地址',
  'p.onsitesupport.startchecktitle': '作业许可详情',
  'p.onsitesupport.startchecktitleimage': '作业许可相关附件',
  'p.onsitesupport.closechecktitle': '完工检查详情',
  'p.onsitesupport.rejectedreason': '拒绝退回理由:',
  'p.onsitesupport.description':
    'ABB低压现场服务远程作业许可申请说明：\n\t1.	服务人员在开工前填写此作业许可。 \n\t2.	待ABB审批人员确认安全条件批准后再开工，必须时可视频确认。\n\t3.	请至少添加“个人全身照“，在不违反客户要求的前提下，尽最大可能补充必要的“现场环境、待操作的设备、现场风险及对应安全措施”等相关图片。\n\t4.	您与ABB审批人员的相互密切配合，将给您的现场服务提供最大的安全保障。\n\t5. 必须提交实时作业时拍的照片，原始照片留底备查。非实时作业或提交的照片做假，将停止作业许可提交权限。\n\t',
  'p.onsitesupport.closedescription': '作业许可完工检查',
  'p.onsitesupport.tsrequestno': '服务单号',
  'p.onsitesupport.onsitesupportstartno': '作业许可单号',
  'p.onsitesupport.submitdate': '提交时间',
  'p.onsitesupport.startdate': '作业许可时间',
  'p.onsitesupport.closedate': '作业许可时间',
  'p.onsitesupport.approvedate': '审批时间',
  'p.onsitesupport.applicant': '提交人',
  'p.onsitesupport.tsr': 'TSR',
  'p.onsitesupport.tsm': 'TSM',
  'p.onsitesupport.status': '审批状态',
  'p.onsitesupport.status.draft': '草稿',
  'p.onsitesupport.status.return': '待重新提交',
  'p.onsitesupport.status.submit': '提交',
  'p.onsitesupport.status.approve': '审批通过',
  'p.onsitesupport.status.close': '关闭',
  'p.onsitesupport.completeddate': '完成时间',
  'p.onsitesupport.workplace': '工作场所类型',
  'p.onsitesupport.approvaltype': '审批类型',
  'p.onsitesupport.servicecompany': '服务单位',
  'p.onsitesupport.projectname': '项目名称',
  'p.onsitesupport.servicecontent': '服务内容',
  'p.onsitesupport.toprepare': '作业许可前准备',
  'p.onsitesupport.articlesofwear': '穿戴劳保用品',
  'p.onsitesupport.safetytraining': '已接受安全培训',
  'p.onsitesupport.siteevironment': '现场环境',
  'p.onsitesupport.issafetyanalysis': '已完成工作安全分析',
  'p.onsitesupport.issafecommunication': '已经和客户进行安全沟通',
  'p.onsitesupport.riskmanagement': '主要风险控制',
  'p.onsitesupport.electricaldamage': '电器伤害（<1kv）',
  'p.onsitesupport.highaltitudeoperation': '高处作业',
  'p.onsitesupport.manualhandling': '人工搬运',
  'p.onsitesupport.otherrisks': '其他风险，请说明',
  'p.onsitesupport.images': '图片区（如未拍照，须说明原因，并至少有全身照）',
  'p.onsitesupport.imagesexplain': '未附全照片原因',
  'p.onsitesupport.approver': '审批人',
  'p.onsitesupport.servicestarttime': '服务开始时间',
  'p.onsitesupport.serviceclosetime': '服务结束时间',
  'p.onsitesupport.toolcleaning': '工具已经清点、处理',
  'p.onsitesupport.elementcleaning': '现场元器件已经清理、无遗漏',
  'p.onsitesupport.hasbeensafelyevacuated':
    '本次服务已完成，无事故，已安全撤出现场',
  'p.onsitesupport.accidentremark': '发生事故的请简述',
  'p.onsitesupport.remark': '备注',
  'p.onsitesupport.images2': '图片',
  'p.onsitesupport.attachments': '附件',
  'p.onsitesupport.location': '当前地点',
  'p.onsitesupport.comments': '备注',
  'p.onsitesupport.end.succress': '提交成功',
  'p.onsitesupport.tsreport.tsrequestno': 'TS服务单号',
  'p.onsitesupport.tsreport.requestno': '作业许可单号',
  'p.onsitesupport.tsreport.assigndate': 'TS指派SP时间',
  'p.onsitesupport.tsreport.abbdistributor': 'ABB客户',
  'p.onsitesupport.tsreport.servicesiteaddress': '故障产品所在地',
  'p.onsitesupport.tsreport.project': '工程项目',
  'p.onsitesupport.tsreport.onsitesupportts': '现场服务TS',
  'p.onsitesupport.tsreport.spname': '服务商名称',
  'p.onsitesupport.tsreport.onsitesupportspe': '现场服务SPE姓名及电话',
  'p.onsitesupport.tsreport.kickoffdatetime': '开工日期',
  'p.onsitesupport.tsreport.closedatetime': '完成日期',
  'p.onsitesupport.tsreport.assignuser': '指派人',

  'p.onsitesupport.startcheck.onsitesupportno': '作业许可编号',
  'p.onsitesupport.startcheck.project': '工程项目',
  'p.onsitesupport.startcheck.statusdisplayname': '审批状态',
  'p.onsitesupport.startcheck.applicantemail': '申请人邮箱',
  'p.onsitesupport.startcheck.applicantname': '申请人姓名',
  'p.onsitesupport.startcheck.applicantcompany': '申请人单位',
  'p.onsitesupport.startcheck.approvername': '审批人姓名',
  'p.onsitesupport.startcheck.havephoto': '是否有现场照片',
  'p.onsitesupport.startcheck.notphotoreason': '现场不允许拍照原因',
  'p.onsitesupport.startcheck.notphotoreasoncomment': '其他原因',
  'p.onsitesupport.startcheck.notphotoreasoncomment.placeholder':
    '无现场照片需提交穿PPE全身照一张，照片能体现客户名称。',
  'p.onsitesupport.startcheck.workservicetype': '工作场所类型',
  'p.onsitesupport.startcheck.servicecontent': '服务内容',
  'p.onsitesupport.startcheck.risk': '涉电风险识别及说明',
  'p.onsitesupport.startcheck.dressphoto': '劳保穿戴配绝缘手套',
  'p.onsitesupport.startcheck.securitynotice':
    '口头安全告知或培训（如有培训签到需拍照上传）',
  'p.onsitesupport.startcheck.securitynotice.upload': '培训签到照片',

  'p.onsitesupport.startcheck.sitecondition': '现场环境情况',
  'p.onsitesupport.startcheck.siteconditionphoto':
    '现场环境照片（站位的上下左右环境情况，关注沟/坑防护，可多张照片）',
  'p.onsitesupport.startcheck.havecommentwithcustomer':
    '口头和客户进行安全沟通',
  'p.onsitesupport.startcheck.otherpowerrisk': '其他电气伤害（<1KV）',
  'p.onsitesupport.startcheck.otherpowerrisk.upload':
    '其他电气伤害（<1KV）照片',
  'p.onsitesupport.startcheck.majorrisk': '主要风险控制',
  'p.onsitesupport.startcheck.carrytype': '人工搬运',
  'p.onsitesupport.startcheck.otherriskcomment': '其他风险，请说明',

  'p.onsitesupport.startcheck.otherphoto':
    '其他照片，如有（如上锁、现场交叉作业隔离），请不要超过5张。',

  'p.onsitesupport.closecheck.onsitesupportno': '作业许可编号',
  'p.onsitesupport.closecheck.project': '工程项目',
  'p.onsitesupport.closecheck.servicestarttime': '服务开始时间',
  'p.onsitesupport.closecheck.serviceclosetime': '服务结束时间',
  'p.onsitesupport.closecheck.toolcleaning': '工具已经清点、处理',
  'p.onsitesupport.closecheck.elementcleaning': '现场元器件已经清理、无遗漏',
  'p.onsitesupport.closecheck.hasbeensafelyevacuated':
    '本次服务已完成，无事故，已安全撤出现场',
  'p.onsitesupport.closecheck.accidentremark': '发生事故的请简述',
  'p.onsitesupport.closecheck.remark': '备注',
  'p.onsitesupport.closecheck.images': '图片',
  'p.onsitesupport.closecheck.attachments': '附件',
  'p.onsitesupport.closecheck.location': '当前地点',

  'p.onsitesupport.report.tsrequestno': '服务单号',
  'p.onsitesupport.report.requestno': '作业许可单号',
  'p.onsitesupport.report.kickoffdatetime': '开工提交时间',
  'p.onsitesupport.report.closedatetime': '完工提交时间',
  'p.onsitesupport.report.applicant': '提交人',
  'p.onsitesupport.report.status': '审批状态',
  'p.onsitesupport.report.approveddate': '审批时间',
  'p.onsitesupport.report.approver': '审批人',
  'p.onsitesupport.report.assignspdate': '指派时间',
  'p.onsitesupport.report.abbdistributor': 'ABB客户',
  'p.onsitesupport.report.servicesiteaddress': '故障产品所在地',
  'p.onsitesupport.report.project': '工程项目',
  'p.onsitesupport.report.applicantcompany': '申请公司',
  'p.onsitesupport.report.spassignconfirmdate': 'SP确认时间',

  'p.tsreturnrequest.area.tsreturninfotitle': '退货单信息',
  'p.tsreturnrequest.returnno': '退货单号',
  'p.tsreturnrequest.status': '状态',
  'p.tsreturnrequest.applicant': '退货申请人',
  'p.tsreturnrequest.applicationdate': '退货申请时间',
  'p.tsreturnrequest.requestno': '服务单号',
  'p.tsreturnrequest.project': '工厂项目',
  'p.tsreturnrequest.shippeddate': '确认收货日期',
  'p.tsreturnrequest.isreceiptdevision': '收货是否有差异',
  'p.tsreturnrequest.area.processinfotitle': '审批处理',
  'p.tsreturnrequestdetail.actualreceivedmaterialno': '收货物料号',
  'p.tsreturnrequestdetail.actualreceivedqty': '收货数量',
  'p.tsreturnrequestdetail.actualreceiveddevationreason': '收货差异原因',
  'p.tsreturnrequest.area.confirmreceiptinfotitle': '确认收货信息',
  'p.larequest.area.servicerequestorinfotitle': '服务申请人信息',
  'p.larequest.area.editservicerequestorinfotitle': '编辑服务申请人信息',
  'p.larequest.servicerequestor': '服务申请人',
  'p.larequest.servicerequestorcompany': '服务申请人公司',
  'p.larequest.servicerequestorphonenumber': '服务申请人电话',
  'p.larequest.servicerequestoremail': '电子邮箱',
  'p.larequest.salesoffice': '销售所在地',
  'p.larequest.responsibleoh': 'ABB订单处理专员',
  'p.larequest.companyaddress': '地址',
  'p.larequest.area.receiptinfotitle': '相关收货信息',
  'p.larequest.receiveddate': '收货时间',
  'p.larequest.servicetype': '服务分类',
  'p.larequest.receivedlocation': '收货地点',
  'p.larequest.shippedlocation': '发货地点',
  'p.larequest.shippedlocationcode': '发货地点编码',
  'p.larequest.area.larequestordetailinfotitle': '产品相关信息',
  'p.larequest.area.attachmentinfotitle': '相关附件信息',
  'p.larequest.outtransportpackageattachment': '最外面运输包装',
  'p.larequest.transportpackageattachment': '运输包装',
  'p.larequest.salesguaranteeattachment': '产品销售包装',
  'p.larequest.barcodeattachment': '销售包装箱上的条码标签',
  'p.larequest.factorycodeattachment': '产品机身上的出厂编码',
  'p.larequest.damagedproductsattachment': '破损产品实物',
  'p.larequest.signformattachment': '签收单',
  'p.larequest.abbsignformattachment': 'ABB签收单',
  'p.larequest.casepackageattachment': '标箱包装',
  'p.larequest.lostlocationattachment': '缺少货物在包装内的位置',
  'p.larequest.labelattachment': '防震防倾斜标签',
  'p.larequestdetail.deliverynoteno': '发货单号',
  'p.larequestdetail.deliverynoteitemno': '发货单行项号',
  'p.larequestdetail.salesorderno': '订单号',
  'p.larequestdetail.salesorderitemno': '订单行项号',
  'p.larequestdetail.packingno': '装箱单号',
  'p.larequestdetail.packingmaterialno': '装箱单号物料号',
  'p.larequestdetail.boxingno': '箱号',
  'p.larequestdetail.mpn': 'MPN',
  'p.larequestdetail.abcclass': 'ABC类',
  'p.larequestdetail.materialdescription': '产品描述',
  'p.larequestdetail.plantcode': '工厂代码',
  'p.larequestdetail.plantname': '工厂名称',
  'p.larequestdetail.receivedmaterialno': '实际收货物料号',
  'p.larequestdetail.receivedmaterialdescription': '实际收货产品描述',
  'p.larequestdetail.packingqty': '装箱清单数量',
  'p.larequestdetail.receivedqty': '实际收到正确物料数量',
  'p.larequestdetail.listprice': '销售单价',//物料面价
  'p.larequestdetail.claimamount': '索赔总金额',
  'p.larequestdetail.relatedtransportation': '运输商',
  'p.larequestdetail.damageqty': '产品破损数量',
  'p.larequestdetail.ispackagedamaged': '货物外包装是否破损',
  'p.larequestdetail.damagedescription': '破损情况说明',
  'p.larequest.area.servicerequestorserviceinfotitle': '其他情况说明',
  'p.larequest.requestno': '服务单号',
  'p.larequest.applicationdate': '服务提交日期',
  'p.larequest.applicant': '服务提交人',
  'p.larequest.status': '服务状态',
  'p.larequest.area.servicerequestbaseinfotitle': '基础信息',
  'p.larequest.salesGroup': 'Sales Group',
  'p.larequest.area.processinfotitle': '审批处理',
  'p.larequest.isaccept': '是否接受',
  'p.larequest.handlereason': '处理理由',
  'p.larequest.remark': '备注说明',
  'p.larequest.otherreason': '其他理由',
  'p.larequest.detailno': '子服务编号',
  'p.larequest.plant': '仓库',

  'p.larequestdetailreplenish.materialno': '物料号',
  'p.larequestdetailreplenish.materialdescription': '产品描述',
  'p.larequestdetailreplenish.qty': '数量',
  'p.larequestdetailreplenish.trackingno': '运单号',
  'p.larequest.salesgroup': 'Sales Group',
  'p.larequest.area.replenishinfotitle': '补货信息',
  'p.larequest.isagree': '是否同意',
  'p.larequest.claim': '索赔记录记录',
  'p.larequest.claim.requestno': '索赔单号',
  'p.larequest.claim.applicant': '索赔提交人',
  'p.larequest.claim.claimtotal': '索赔金额',
  'p.larequest.claim.currency': '币种',
  'p.larequest.claim.status': '索赔状态',
  'p.larequest.claim.submitdate': '索赔提交日期',
  'p.larequest.claim.completedate': '索赔完成日期',
  'p.larequest.claim.waybill': '运单号',
  'p.larequest.claim.ncr': 'NCR＃',
  'p.larequest.claim.creditnote': 'Credit Note＃',
  'p.larequest.claim.creditnotetotal': 'Credit 金额',

  'p.larequestdetail.handlemethod': '处理类型',
  'p.larequestdetail.area.tmcapprovalinfotitle': 'TMC处理信息',
  'p.lareplacementrequest.area.larequestinfotitle': '服务单信息',
  'p.lareplacementrequest.larequestno': '服务单号',
  'p.lareplacementrequest.larequestapplicationdate': '服务提交日期',
  'p.lareplacementrequest.area.baseinfotitle': '基本信息',
  'p.lareplacementrequest.requestorcompany': '公司名称',
  'p.lareplacementrequest.requestorphonenumber': '联系方式',
  'p.lareplacementrequest.companyaddress': '联系地址',
  'p.lareplacementrequest.area.applyinfotitle': '补换货申请信息',
  'p.lareplacementrequest.description': '备注说明',
  'p.lareplacementrequest.area.detailinfotitle': '补换货产品信息',
  'p.lareplacementrequestdetail.detailno': '序号',
  'p.lareplacementrequestdetail.salesorderno': '订单号',
  'p.lareplacementrequestdetail.materialno': '物料号',
  'p.lareplacementrequestdetail.product': '产品名称',
  'p.lareplacementrequestdetail.productdescription': '产品描述',
  'p.lareplacementrequestdetail.qty': '产品数量',
  'p.lareplacementrequestdetail.listprice': '单价',
  'p.lareplacementrequestdetail.totalamount': '总金额',
  'p.lareplacementrequestdetail.listprice2': '单价（面价）',
  'p.lareplacementrequestdetail.totalamount2': '总金额（面价）',
  'p.lareplacementrequestdetail.remark': '备注',
  'p.lareplacementrequestdetail.currency': '币种',
  'p.lareplacementrequest.needwdreturn': '需要客户退回错发破损产品',
  'p.lareplacementrequest.needdmreturn': '需要客户退回错发破损产品',
  'p.lareplacementrequest.totalcost': '总金额',
  'p.lareplacementrequest.totalcost2': '总金额（面价）',
  'p.lareplacementrequest.replacementno': '补换货单号',
  'p.lareplacementrequest.status': '服务状态',
  'p.lareplacementrequest.applicationdate': '补换货提交日期',
  'p.lareplacementrequest.applicant': '补换货提交人',
  'p.lareplacementrequest.totalamount': '总金额',
  'p.lareplacementrequest.currency': '币种',
  'p.lareplacementrequest.responsibleoh': 'ABB订单处理专员',
  'p.lareplacementrequest.area.processinfotitle': '审批意见',
  'p.lareplacementrequest.compensationreason': '赔付原因',
  'p.lareplacementrequest.compensationotherReason': '其他原因',
  'p.lareplacementrequest.compensationamount': '赔付金额',
  'p.lareplacementrequest.plant': '仓库',
  'p.lareturnrequest.returnno': '退货单号',
  'p.lareturnrequest.larequestno': '服务单号',
  'p.lareturnrequest.applicant': '退货申请人',
  'p.lareturnrequest.applicationdate': '退货申请时间',
  'p.lareturnrequest.responsibleoh': 'ABB订单处理专员',
  'p.lareturnrequest.requestorcompany': '公司名称',
  'p.lareturnrequest.sendername': '退货联系人',
  'p.lareturnrequest.senderphone': '退货联系电话',
  'p.lareturnrequest.companyaddress': '退货联系地址',
  'p.lareturnrequest.area.lareturninfotitle': '退货单信息',
  'p.lareturnrequest.status': '状态',
  'p.lareturnrequest.area.returninfotitle': '退货信息',
  'p.lareturnrequest.shippeddate': '预计提货日期',
  'p.lareturnrequest.shippedlocation': '退货地点',
  'p.lareturnrequest.shiptoaddress': '提货地址',
  'p.lareturnrequest.area.detailinfotitle': '退货产品信息',
  'p.lareturnrequest.area.otherinfotitle': '其他信息',
  'p.lareturnrequest.materialhandle': '实物处理',
  'p.lareturnrequest.handelRemark': '备注信息',
  'p.lareturnrequest.handelattachment': '上传附件',


  'p.lareturnrequestdetail.deliverynoteno': '发货单号',
  'p.lareturnrequestdetail.deliverynoteitemno': '发货单行项号',
  'p.lareturnrequestdetail.salesorderno': '订单号',
  'p.lareturnrequestdetail.materialno': '物料号',
  'p.lareturnrequestdetail.qty': '退货数量',
  'p.lareturnrequestdetail.product': '产品名称',
  'p.lareturnrequestdetail.pieces': '退货件数',
  'p.lareturnrequestdetail.weight': '重量',
  'p.lareturnrequestdetail.size': '尺寸',
  'p.lareturnrequestdetail.comments': '备注',
  'p.lareturnrequestdetail.actualreceivedmaterialno': '收货物料号',
  'p.lareturnrequestdetail.actualreceivedqty': '收货数量',
  'p.lareturnrequestdetail.actualreceiveddevationreason': '差异说明',
  'p.spmasterdata.spcode': '服务商代码',
  'p.spmasterdata.spname': '服务商名称',
  'p.spmasterdata.area': '区域',
  'p.spmasterdata.contactuser': '联系人',
  'p.spmasterdata.telphone': '电话',
  'p.spmasterdata.rate': '本地服务费用',
  'p.spmasterdata.triprate': '出差补贴费用',
  'p.spmasterdata.rateunit': '(CNY)人/天',

  'p.spmasterdata.remark': '备注',
  'p.spmasterdata.isopen': '是否启用',
  'p.tsrequest.sp': '服务商',
  'p.tsrequest.spestimateddate': '预计到场日期',
  'p.tsrequest.assignonsitesupportuser': '指派服务人员',

  'p.factoryclaims.requestno': '服务单号',
  'p.factoryclaims.clrequestno': '索赔单号',
  'p.factoryclaims.maintenancetitle': '免费维修信息',
  'p.factoryclaims.childenrequestno': '子服务单号',
  'p.factoryclaims.servicetype': '服务分类',
  'p.factoryclaims.claimsubmitdate': '索赔提交日期',
  'p.factoryclaims.claimsuser': '索赔提交人',
  'p.factoryclaims.claimscomments': '索赔理由',
  'p.factoryclaims.company': '公司名称',
  'p.factoryclaims.responsibleoh': 'ABB订单处理专员',
  'p.factoryclaims.warehouseaddress': '仓库地址',
  'p.factoryclaims.warehouseuser': '仓库联系人',
  'p.factoryclaims.warehousetelphone': '仓库联系人电话',
  'p.factoryclaims.planName': '工厂名称',
  'p.factoryclaims.materialno': '物料号',
  'p.factoryclaims.materialdetail': '产品描述',
  'p.factoryclaims.qty': '数量',
  'p.factoryclaims.waybillno': '运单号',
  'p.factoryclaims.ncr': 'NCR#',
  'p.factoryclaims.factoryissubmit': '是否接受',
  'p.factoryclaims.claimsdate': '索赔日期',
  'p.factoryclaims.plantcode': '工厂代码',
  'p.factoryclaims.claimstotal': '客户索赔金额',
  'p.factoryclaims.felltotal': '免费补换货金额',
  'p.factoryclaims.plantname': '工厂名称',
  'p.factoryclaims.planisreject': '工厂是否拒绝',
  'p.factoryclaims.planisreplenishment': '工厂是否补换货',
  'p.factoryclaims.planismaintenance': '工厂是否维修',
  'p.factoryclaims.iscreditnote': '是否提供Credit Note',
  'p.factoryclaims.creditnote': 'Credit Note',
  'p.factoryclaims.creditnotetotal': 'Credit Note金额',
  'p.factoryclaims.currency': '币种',
  'p.factoryclaims.total': '金额',
  'p.factoryclaims.handlefunction': '处理方式',
  'p.factoryclaims.vendorcode': '工厂代码',
  'p.factoryclaims.pendingby': 'Pending By',
  'p.factoryclaims.laservicerequestbaseinfotitle': '服务单基础信息',
  'p.factoryclaims.baseinfotitle': '索赔基础信息',
  'p.factoryclaims.clstatus': '索赔状态',
  'p.factoryclaims.remark': '备注',

  'p.onsiteexpenses.detailtitle': '基本信息',
  'p.onsiteexpenses.supprotcount': '现场服务次数',
  'p.onsiteexpenses.tsrequestno': '服务单号',
  'p.onsiteexpenses.servicecontactperson': '服务现场联系人',
  'p.onsiteexpenses.servicerequestor': '服务申请人姓名',
  'p.onsiteexpenses.servicecontactpersonphonenumber': '服务现场联系电话',
  'p.onsiteexpenses.project': '工程项目',
  'p.onsiteexpenses.region': '故障产品所在地',
  'p.onsiteexpenses.iscompleted': '是否解决问题',
  'p.onsiteexpenses.allServiceComment': '总体服务评价',
  'p.onsiteexpenses.allNoServiceType': '不满意类型',
  'p.onsiteexpenses.otherComment':'其他意见',
  'p.onsiteexpenses.expensestitle': '费用信息',
  'p.onsiteexpenses.istrip': '是否出差',
  'p.onsiteexpenses.serviceday': '服务天数',
  'p.onsiteexpenses.servicepersonday': '服务人/天',
  'p.onsiteexpenses.ratedetail':
    '费用说明：出差补贴费用人/天{0}，本地服务费用人/天{1}',

  'p.onsiteexpenses.servicepersoncount': '服务人数',
  'p.onsiteexpenses.otherexpenses': '其他费用(CNY)',
  'p.onsiteexpenses.otherexpensesremark': '备注',
  'p.onsiteexpenses.expensestotal': '费用合计(CNY)',
  'p.onsiteexpenses.attachmenttitle': '相关附件',
  'p.onsiteexpenses.returnRecording': '回访录音',
  'p.onsiteexpenses.attachment': '附件',
  'p.onsiteexpenses.remark': '备注',
  'p.onsiteexpenses.requestno': '报销申请单号',
  'p.onsiteexpenses.approvalexpenses': '审批金额(CNY)',
  'p.onsiteexpenses.servicedate': '服务日期',
  'p.onsiteexpenses.appliedamount': '申请金额(CNY)',
  'p.onsiteexpenses.processinfotitle': '审批意见',
  'p.onsiteexpenses.report.requestno': '费用报销单号',
  'p.onsiteexpenses.report.onsiterequestno': '现场服务单号',
  'p.onsiteexpenses.report.assignuser': '指派人',
  'p.onsiteexpenses.report.spname': '服务商名称',
  'p.onsiteexpenses.report.serviceday': '服务天数',
  'p.onsiteexpenses.report.servicepersoncount': '服务人数',
  'p.onsiteexpenses.report.otherexpenses': '其他费用(CNY)',
  'p.onsiteexpenses.report.appliedexpenses': '申请金额(CNY)',
  'p.onsiteexpenses.report.currency': '币种',
  'p.onsiteexpenses.report.status': '状态',
  'p.onsiteexpenses.report.status.submit': '待重新提交',
  'p.onsiteexpenses.report.status.approve': '审批中',
  'p.onsiteexpenses.report.status.close': '已完成',
  'p.onsiteexpenses.report.submitdate': '报销提交时间',
  'p.onsiteexpenses.report.approvaldate': '审批完成时间',
  'p.onsiteexpenses.report.remark': '备注',
  'p.onsiteexpenses.report.allServiceComment': '满意度',
  'p.onsiteexpenses.report.allNoServiceType': '不满意度类型',
  'p.onsiteexpenses.report.otherComment': '不满意度意见',
  'p.onsiteexpenses.report.servicepersonday': '服务人/天',
  'p.onsiteexpenses.report.assigndate': '指派时间',
  'p.onsiteexpenses.description':
    '审批须上传附件要求：\n\tABB电气服务记录表扫描件正面（同一服务两人及以上均需签字）\n\t出差大于1天预先申请的TSR审批记录，邮件或截屏图片\n\t实际出差费用超过2000元的费用报销，需要上传交通凭证（高速公路过路费，车票，飞机票等有时间记录的票据）和住宿凭证（有住宿时间）作为审批依据。\n\t其他费用如登高证，出海证等资格证书以及为履行服务协议所需的其他合理费用，需提交技术服务经理的审批记录邮件和费用票据图片。\n\t报销还需上传SP服务的回访录音的音频文件附件作为审批依据，“总体服务评价”填写内容需与提交的回访录音内容一致。\n\t',
  'p.tsreturnrequest.isreceipted': '仓库是否收货',
  'p.tsreplacementrequest.completedtime': '审批完成时间',
  'p.tsreplacementrequest.salesgroup': 'Sales Group',
  'p.tsreplacementrequest.saporderno': 'SAP补换货单号',
  'p.lareturnrequest.compensationreason': '赔付原因',
  'p.tsreturnrequest.isreceipt': '是否收货',
  'p.lareplacementrequest.completedtime': '审批完成时间',
  'p.lareplacementrequest.saporderno': 'SAP补换货单号',
  'p.smstemplate.classify': '模板分类',
  'p.smstemplate.name': '模板名称',
  'p.smstemplate.code': '模板编码',
  'p.smstemplate.bodytemplate': '短信内容',
  'p.smstemplate.createtitle': '新建短信模板',
  'p.smstemplate.edittitle': '编辑短信模板',
  'p.tsrequest.tsrequestactionloglist': '操作日志记录',
  'p.tsrequest.actiondate': '操作时间',
  'p.tsrequest.actionowner': '操作人',
  'p.tsrequest.actionRequestno': '操作记录',
  'p.tsrequest.actionname': '操作名称',
  'p.tsrequest.actioncomments': '备注说明',
  'p.tsrequest.copyfromtsrequestno': '原服务单号',
  'p.larequest.companyname': '公司名称',
  'p.larequest.responsibleoh2': '订单专员',
  'p.larequestdetail.pendingapprover': '待处理角色',
  'p.larequestdetail.lareplacementrequestlist': '补换货记录',
  'p.larequest.area.attachmentinfotitletips': '请至少上传一个附件',
  'p.action.createsaporderconfirmtitle': '确认要创建SAP补换货单吗？',
  'p.lareplacementrequest.closeReplenishtitle': '关闭换货',
  'p.larequestdetail.lareturnrequestlist': '退货记录',
  'p.larequest.optiontype': '处理结果',
  'p.optiontype.accept': '接受',
  'p.optiontype.reject': '拒绝',
  'p.asynctask.type': '任务类型',
  'p.asynctask.name': '任务名称',
  'p.asynctask.description': '任务描述',
  'p.asynctask.status': '任务状态',
  'p.table.createdby': '创建人',
  'p.table.createdtime': '创建时间',
  'p.asynctask.executetime': '执行时间',
  'p.cboldashboard.area.tsrequestlist': '质量问题投诉',
  'p.cboldashboard.area.larequestlist': '错发破损投诉',
  'p.cboldashboard.timetyp0': '近七天',
  'p.cboldashboard.timetyp1': '近一个月',
  'p.cboldashboard.timetyp2': '近三个月',
  'p.cboldashboard.timetyp3': '近两年',
  'p.replacementrequest.servicetype': '服务类型',
  'p.replacementrequest.servicetype1': '错发破损服务',
  'p.replacementrequest.servicetype2': '质量问题服务',
  'p.lareplacementrequest.salesgroup': 'Sales Group',
  'p.larequest.noticecontent':
    '<span>*微型断路器和面板开关类产品的破损类服务单，请填写质量技术服务 <br/>**产品独立小包装内外产品不符，产品独立小包装内附件缺失。请填写质量技术服务</span>',
  'p.tsreplacementrequest.shippedtocitytip': '输入“北京”，不是“北京市”',
  'p.producttypeclassify.createtitle': '新建产品类型分类',
  'p.producttypeclassify.updatetitle': '编辑产品类型分类',
  'p.producttype.type': '类型分类',
  'p.producttype.name': '类型名称',
  'p.producttype.code': '类型编码',
  'p.producttype.isenabled': '是否启用',
  'p.producttype.createtitle': '新建产品类型',
  'p.producttype.edittitle': '编辑产品类型',
  'p.problemtype.name': '故障类型',
  'p.problemtype.isenabled': '是否启用',
  'p.problemtype.producttype': '产品类型分类',
  'p.problemtype.producttypename': '产品类型',
  'p.problemtype.createtitle': '新建故障分类',
  'p.problemtype.edittitle': '编辑故障分类',
  'p.tsreturnsetting.returnamount': '退货金额',
  'p.tsreturnsetting.hkreturnamount': '香港退货金额',
  'p.tsreturnsetting.validstartdate': '有效开始日期',
  'p.tsreturnsetting.createtitle': '新建质量服务单退货设置',
  'p.tsreturnsetting.edittitle': '编辑质量服务单退货设置',
  'p.tsreplacementrequest.closeReturntitle': '关闭退货',
  'p.tsreturnrequestdetail.unreturnqty': '未退货数量',
  'p.tsreplacementrequest.closeReturnattachmenttitle': '关闭退货附件信息',
  'p.larequestdetail.area.communicateattachmentinfotitle': '沟通附件信息',
  'p.tsreplacementrequest.producttype': '产品类型',
  'p.common.communicateattachment': '沟通附件',
  'p.tsreplacementrequest.closereplenishtitle': '质量服务单补换货关闭信息',
  'p.tsreplacementrequest.rejectreplenishtitle': '质量服务单补换货拒绝信息',
  'p.lareplacementrequest.closereplenishtitle': '错发破损服务单补换货关闭信息',
  'p.action.reexecuteconfirmtitle': '确认要重新执行该任务吗？',
  'p.tsrequest.area.servicerequestfinalTechnicalsolutiontitle': '最终技术建议',
  'p.larequest.changereasons': '变更理由',
  'p.larequest.buyerhandlingmethod': '处理方式',
  'p.larequestdetail.creditnote': 'Credit Note',
  'p.larequestdetail.creditamount': '金额',
  'p.printmodal.modaltitle':'打印',
  'p.internaldeliverydifferencesrequest.area.servicerequestorinfotitle': '服务申请人信息',
  'p.internaldeliverydifferencesrequest.servicerequestor': '服务申请人',
  'p.internaldeliverydifferencesrequest.servicerequestorcompany': '服务申请人公司',
  'p.internaldeliverydifferencesrequest.servicerequestorphonenumber': '服务申请人电话',
  'p.internaldeliverydifferencesrequest.servicerequestoremail': '服务申请人邮箱',
  'p.internaldeliverydifferencesrequest.area.receiptinfotitle': '相关收货信息',
  'p.internaldeliverydifferencesrequest.plant': 'Plant',
  'p.internaldeliverydifferencesrequest.deliveryDate': '收货日期',
  'p.internaldeliverydifferencesrequest.area.internaldeliverydifferencesdetailinfotitle': '差异产品信息',
  'p.internaldeliverydifferencesrequestdetail.PONumber': 'PO#',
  'p.internaldeliverydifferencesrequestdetail.POItem': 'Item#',
  'p.internaldeliverydifferencesrequestdetail.VendorCode': '工厂代码',
  'p.internaldeliverydifferencesrequestdetail.VendorName': '工厂名称',
  'p.internaldeliverydifferencesrequestdetail.ProblemType': '责任区分',
  'p.internaldeliverydifferencesrequestdetail.DifferenceType': '异常类型',
  'p.internaldeliverydifferencesrequestdetail.DifferenceDescription': '具体描述',
  'p.internaldeliverydifferencesrequestdetail.IsCNABBCarrier': 'CNABB运输商',
  'p.internaldeliverydifferencesrequestdetail.DeliveryNoteNo': '运单号',
  'p.internaldeliverydifferencesrequestdetail.BoxingNo': '箱号',
  'p.internaldeliverydifferencesrequestdetail.MaterialNo': '物料号',
  'p.internaldeliverydifferencesrequestdetail.MaterialDescription': '物料描述',
  'p.internaldeliverydifferencesrequestdetail.MG': 'MG',
  'p.internaldeliverydifferencesrequestdetail.MGDescription': 'MG描述',
  'p.internaldeliverydifferencesrequestdetail.BU': 'BU',
  'p.internaldeliverydifferencesrequestdetail.PG': 'PG',
  'p.internaldeliverydifferencesrequestdetail.SLocation': 'Sloc',
  'p.internaldeliverydifferencesrequestdetail.Quantity': '应收数量',
  'p.internaldeliverydifferencesrequestdetail.IssueQty': '剩余问题数量',
  'p.internaldeliverydifferencesrequestdetail.NetPrice': 'Unit Price',
  'p.internaldeliverydifferencesrequestdetail.MovingPrice': 'Moving Price',
  'p.internaldeliverydifferencesrequestdetail.TotalPrice': '差异总价值',
  'p.internaldeliverydifferencesrequest.area.attachmentinfotitle': '相关附件信息',
  'p.internaldeliverydifferencesrequest.area.servicerequestorserviceinfotitle': '服务申请人服务要求',
  'p.internaldeliverydifferencesrequest.area.servicerequestbaseinfotitle': '服务申请单信息',
  'p.internaldeliverydifferencesrequest.requestno': '服务单号',
  'p.internaldeliverydifferencesrequest.status': '服务状态',
  'p.internaldeliverydifferencesrequest.submitter': '服务提交人',
  'p.internaldeliverydifferencesrequest.submittime': '服务提交日期',
  'p.internaldeliverydifferencesrequest.internaldeliverydifferencesrequestactionloglist': '操作日志记录',
  'p.internaldeliverydifferencesrequest.actiondate': '操作时间',
  'p.internaldeliverydifferencesrequest.actionowner': '操作人',
  'p.internaldeliverydifferencesrequest.actionRequestno': '操作记录',
  'p.internaldeliverydifferencesrequest.actionname': '操作名称',
  'p.internaldeliverydifferencesrequest.actioncomments': '备注说明',
  'p.internaldeliverydifferencesrequest.applicationdate': '服务提交日期',
  'p.internaldeliverydifferencesrequest.applicant': '服务提交人',
  'p.internaldeliverydifferencesrequest.area.processinfotitle': '审批意见',
  'p.internaldeliverydifferencesrequest.isCarrierPaid': '运输商赔付',
  'p.internaldeliverydifferencesrequest.isReturnRepair': '可返厂维修',
  'p.internaldeliverydifferencesrequest.paidAmount': '赔付金额',
  'p.internaldeliverydifferencesrequest.remark': '备注说明',
  'p.internaldeliverydifferencesrequest.processingResult': '处理结果',
  'p.internaldeliverydifferencesrequest.area.tmcprocessinfotitle': 'TMC审批意见',
  'p.internaldeliverydifferencesrequest.area.buyerprocessinfotitle': 'Buyer审批意见',
  'p.internaldeliverydifferencesrequest.area.buyerconfirmprocessinfotitle': 'Buyer确认意见',
  'p.internaldeliverydifferencesrequest.pendingRoleName': '待处理角色',
  'p.internaldeliverydifferencesrequest.area.finalprocessingpesultinfotitle': '最终处理结果信息',
  'p.internaldeliverydifferencesrequest.subcontractPO': 'Subcontract PO#',
  'p.internaldeliverydifferencesrequest.isAcceiptZQIN': '客户让步接收',
  'p.internaldeliverydifferencesrequest.isClaim': '工厂赔付',
  'p.internaldeliverydifferencesrequest.isClaim2': '向工厂索赔',
  'p.internaldeliverydifferencesrequest.waybillNumber': '运单号',
  'p.internaldeliverydifferencesrequest.submittsrequesttitle': '质量服务单信息',
  'p.internaldeliverydifferencesrequest.isZQINProduct': 'ZQIN库位产品',
  'p.internaldeliverydifferencesrequest.detailNo': '子服务单号',
  'p.abroaddeliverydifferencesrequest.area.servicerequestorinfotitle': '服务申请人信息',
  'p.abroaddeliverydifferencesrequest.servicerequestor': '服务申请人',
  'p.abroaddeliverydifferencesrequest.servicerequestorcompany': '服务申请人公司',
  'p.abroaddeliverydifferencesrequest.servicerequestorphonenumber': '服务申请人电话',
  'p.abroaddeliverydifferencesrequest.servicerequestoremail': '服务申请人邮箱',
  'p.abroaddeliverydifferencesrequest.area.receiptinfotitle': '相关收货信息',
  'p.abroaddeliverydifferencesrequest.plant': 'Plant',
  'p.abroaddeliverydifferencesrequest.deliveryDate': '收货日期',
  'p.abroaddeliverydifferencesrequest.area.abroaddeliverydifferencesdetailinfotitle': '差异产品信息',
  'p.abroaddeliverydifferencesrequestdetail.PONumber': 'PO#',
  'p.abroaddeliverydifferencesrequestdetail.POItem': 'Item#',
  'p.abroaddeliverydifferencesrequestdetail.VendorCode': '工厂代码',
  'p.abroaddeliverydifferencesrequestdetail.VendorName': '工厂名称',
  'p.abroaddeliverydifferencesrequestdetail.ProblemType': '责任区分',
  'p.abroaddeliverydifferencesrequestdetail.DifferenceType': '异常类型',
  'p.abroaddeliverydifferencesrequestdetail.DifferenceDescription': '具体描述',
  'p.abroaddeliverydifferencesrequestdetail.DeliveryNoteNo': '运单号',
  'p.abroaddeliverydifferencesrequestdetail.BoxingNo': '箱号',
  'p.abroaddeliverydifferencesrequestdetail.MaterialNo': '物料号',
  'p.abroaddeliverydifferencesrequestdetail.MaterialDescription': '物料描述',
  'p.abroaddeliverydifferencesrequestdetail.MG': 'MG',
  'p.abroaddeliverydifferencesrequestdetail.MGDescription': 'MG描述',
  'p.abroaddeliverydifferencesrequestdetail.BU': 'BU',
  'p.abroaddeliverydifferencesrequestdetail.PG': 'PG',
  'p.abroaddeliverydifferencesrequestdetail.SLocation': 'Sloc',
  'p.abroaddeliverydifferencesrequestdetail.ReceivableQty': '应收数量',
  'p.abroaddeliverydifferencesrequestdetail.ReceivedQty': '实收数量',
  'p.abroaddeliverydifferencesrequestdetail.DifferenceQty': '收货差异数量',
  'p.abroaddeliverydifferencesrequestdetail.Quantity': '剩余问题数量',
  'p.abroaddeliverydifferencesrequestdetail.UnitPrice': 'Unit Price',
  'p.abroaddeliverydifferencesrequestdetail.MovingPrice': 'Moving Price',
  'p.abroaddeliverydifferencesrequestdetail.EstimatedValue': '差异总价值',
  'p.abroaddeliverydifferencesrequest.area.attachmentinfotitle': '相关附件信息',
  'p.abroaddeliverydifferencesrequest.area.itemattachmentinfotitle': '差异产品附件信息',
  'p.abroaddeliverydifferencesrequest.area.servicerequestorserviceinfotitle': '服务申请人服务要求',
  'p.abroaddeliverydifferencesrequest.area.servicerequestbaseinfotitle': '服务申请单信息',
  'p.abroaddeliverydifferencesrequest.requestno': '服务单号',
  'p.abroaddeliverydifferencesrequest.status': '服务状态',
  'p.abroaddeliverydifferencesrequest.submitter': '服务提交人',
  'p.abroaddeliverydifferencesrequest.submittime': '服务提交日期',
  'p.abroaddeliverydifferencesrequest.abroaddeliverydifferencesrequestactionloglist': '操作日志记录',
  'p.abroaddeliverydifferencesrequest.actiondate': '操作时间',
  'p.abroaddeliverydifferencesrequest.actionowner': '操作人',
  'p.abroaddeliverydifferencesrequest.actionRequestno': '操作记录',
  'p.abroaddeliverydifferencesrequest.actionname': '操作名称',
  'p.abroaddeliverydifferencesrequest.actioncomments': '备注说明',
  'p.abroaddeliverydifferencesrequest.applicationdate': '服务提交日期',
  'p.abroaddeliverydifferencesrequest.applicant': '服务提交人',
  'p.abroaddeliverydifferencesrequest.area.processinfotitle': '审批意见',
  'p.abroaddeliverydifferencesrequest.processingResult': '处理结果',
  'p.abroaddeliverydifferencesrequest.pendingRoleName': '待处理角色',
  'p.abroaddeliverydifferencesrequestdetail.MPN': 'MPN#',
  'p.abroaddeliverydifferencesrequestdetail.ABCIndicator': 'ABC Indicator',
  'p.abroaddeliverydifferencesrequestdetail.UnitofMeasure': 'Unit of Measure',
  'p.abroaddeliverydifferencesrequestdetail.ShipmentNo': '运单号',
  'p.abroaddeliverydifferencesrequestdetail.SNNumber': 'S/N',
  'p.abroaddeliverydifferencesrequestdetail.SparePartsInformation': '备件信息',
  'p.abroaddeliverydifferencesrequestdetail.Bin': 'BIN',
  'p.abroaddeliverydifferencesrequestdetail.InStorageDate': '入库日期',
  'p.abroaddeliverydifferencesrequestdetail.Description': '备注说明',
  'p.abroaddeliverydifferencesrequestdetail.ReissueDescription': '补发情况',
  'p.abroaddeliverydifferencesrequestdetail.IsTSRepair': 'TS可维修',
  'p.abroaddeliverydifferencesrequestdetail.IsTSReceipt': 'TS可收货',
  'p.abroaddeliverydifferencesrequestdetail.IsDamaged': '运输包装是否破损',
  'p.abroaddeliverydifferencesrequestdetail.IsDamp': '运输包装是否潮湿',
  'p.abroaddeliverydifferencesrequestdetail.TotalPrice': '订单总金额',

  'p.abroaddeliverydifferencesrequest.area.approvalloginfotitle': '审批日志信息',
  'p.abroaddeliverydifferencesrequest.ClaimType': '赔付类型',
  'p.abroaddeliverydifferencesrequest.DamageDescription': '破损状况',
  'p.abroaddeliverydifferencesrequest.ClaimAmount': '索赔金额',
  'p.abroaddeliverydifferencesrequest.ClaimDate': '索赔日期',
  'p.abroaddeliverydifferencesrequest.PaidAmount': '赔付金额',
  'p.abroaddeliverydifferencesrequest.CarrierName': '运输商',
  'p.abroaddeliverydifferencesrequest.PreClaimDate': '预索赔日期',
  'p.abroaddeliverydifferencesrequest.area.customprocessinfotitle': 'Customs审批意见',
  'p.abroaddeliverydifferencesrequest.InStorageDate': '入库日期',
  'p.abroaddeliverydifferencesrequest.ReissueDescription': '补发情况',
  'p.abroaddeliverydifferencesrequest.StorageDescription': '库位情况',
  'p.abroaddeliverydifferencesrequest.area.warehouseprocessinfotitle': '库房审批意见',
  'p.abroaddeliverydifferencesrequest.isZQINProduct': 'ZQIN库位产品',
  'p.abroaddeliverydifferencesrequest.isAcceiptZQIN': '客户让步接收',
  'p.abroaddeliverydifferencesrequest.isClaim': '工厂赔付',
  'p.abroaddeliverydifferencesrequest.NCRNumber': 'NCR#',
  'p.abroaddeliverydifferencesrequest.CNNumber': 'CN#',
  'p.abroaddeliverydifferencesrequest.CNAmount': 'CN金额',
  'p.abroaddeliverydifferencesrequest.WaybillNumber': '补货运单号',
  'p.abroaddeliverydifferencesrequest.ReplenishDate': '补货日期',
  'p.abroaddeliverydifferencesrequest.CNAttachment': 'CN附件',
  'p.abroaddeliverydifferencesrequest.area.buyerprocessinfotitle': 'Buyer审批意见',
  'p.abroaddeliverydifferencesrequest.detailNo': '子服务单号',
  'p.abroaddeliverydifferencesrequestdetail.Attachment': '附件',
  'p.abroaddeliverydifferencesrequest.ProcessingResult': '处理结果',

  'p.instoragedeliverydifferencesrequest.requestno': '服务单号',
  'p.instoragedeliverydifferencesrequest.status': '服务状态',
  'p.instoragedeliverydifferencesrequest.VendorCode': '工厂代码',
  'p.instoragedeliverydifferencesrequest.servicerequestor': '服务申请人',
  'p.instoragedeliverydifferencesrequest.plant': 'Plant',
  'p.instoragedeliverydifferencesrequest.DiscoveryDate': '发现日期',
  'p.instoragedeliverydifferencesrequest.MaterialNo': '物料号',
  'p.instoragedeliverydifferencesrequest.VendorName': '工厂名称',
  'p.instoragedeliverydifferencesrequest.pendingRoleName': '待处理角色',
  'p.instoragedeliverydifferencesrequest.applicationdate': '服务提交日期',
  'p.instoragedeliverydifferencesrequest.area.servicerequestorinfotitle': '服务申请人信息',
  'p.instoragedeliverydifferencesrequest.servicerequestorcompany': '服务申请人公司',
  'p.instoragedeliverydifferencesrequest.servicerequestorphonenumber': '服务申请人电话',
  'p.instoragedeliverydifferencesrequest.servicerequestoremail': '服务申请人邮箱',
  'p.instoragedeliverydifferencesrequest.area.receiptinfotitle': '相关收货信息',
  'p.instoragedeliverydifferencesrequest.area.instoragedeliverydifferencesdetailinfotitle': '异常产品信息',
  'p.instoragedeliverydifferencesrequest.area.attachmentinfotitle': '相关附件信息',
  'p.instoragedeliverydifferencesrequest.area.servicerequestorserviceinfotitle': '服务申请人服务要求',
  'p.instoragedeliverydifferencesrequestdetail.MaterialNo': '物料号',
  'p.instoragedeliverydifferencesrequestdetail.MaterialDescription': '物料描述',
  'p.instoragedeliverydifferencesrequestdetail.VendorCode': '工厂代码',
  'p.instoragedeliverydifferencesrequestdetail.VendorName': '工厂名称',
  'p.instoragedeliverydifferencesrequestdetail.BU': 'BU',
  'p.instoragedeliverydifferencesrequestdetail.PG': 'PG',
  'p.instoragedeliverydifferencesrequestdetail.SLocation': 'Sloc',
  'p.instoragedeliverydifferencesrequestdetail.Quantity': '剩余问题数量',
  'p.instoragedeliverydifferencesrequestdetail.MovingPrice': 'Moving Price',
  'p.instoragedeliverydifferencesrequestdetail.Responsibility': '责任区分',
  'p.instoragedeliverydifferencesrequestdetail.ProblemType': '异常类型',
  'p.instoragedeliverydifferencesrequestdetail.ProblemDescription': '具体描述',
  'p.instoragedeliverydifferencesrequestdetail.IsRepair': '工厂是否可维修',//工厂维修
  'p.instoragedeliverydifferencesrequestdetail.ClaimAmount': '赔付金额',
  'p.instoragedeliverydifferencesrequest.area.servicerequestbaseinfotitle': '服务申请单信息',
  'p.instoragedeliverydifferencesrequest.instoragedeliverydifferencesrequestactionloglist': '操作日志记录',
  'p.instoragedeliverydifferencesrequest.actiondate': '操作时间',
  'p.instoragedeliverydifferencesrequest.actionowner': '操作人',
  'p.instoragedeliverydifferencesrequest.actionRequestno': '操作记录',
  'p.instoragedeliverydifferencesrequest.actionname': '操作名称',
  'p.instoragedeliverydifferencesrequest.actioncomments': '备注说明',
  'p.instoragedeliverydifferencesrequest.submitter': '服务提交人',
  'p.instoragedeliverydifferencesrequest.submittime': '服务提交时间',
  'p.instoragedeliverydifferencesrequest.area.approvalloginfotitle': '审批日志信息',
  'p.instoragedeliverydifferencesrequest.applicant': '服务提交人',
  'p.instoragedeliverydifferencesrequest.area.processinfotitle': '审批意见',
  'p.instoragedeliverydifferencesrequest.IsRepaireExchange': '工厂是否可维修',//维修更换
  'p.instoragedeliverydifferencesrequest.ProcessingResult': '处理结果',
  'p.instoragedeliverydifferencesrequest.area.mpprocessinfotitle': 'MP审批意见',
  'p.instoragedeliverydifferencesrequest.area.buyerprocessinfotitle': 'Buyer审批意见',
  'p.instoragedeliverydifferencesrequest.subcontractPO': 'Subcontract PO#',
  'p.instoragedeliverydifferencesrequest.area.finalprocessingpesultinfotitle': '最终处理结果',
  'p.instoragedeliverydifferencesrequest.detailNo': '子服务单号',
  'p.lareturnrequest.ReturnHandlingOpinion': '处理意见',
  'p.lareturnrequest.CompensationReason': '赔付原因',
  'p.carrier.name': '运输商名称',
  'p.carrier.code': '运输商编码',
  'p.carrier.notifyEmails': '通知邮箱',
  'p.carrier.isenabled': '是否启用',
  'p.carrier.createtitle': '新建运输商',
  'p.carrier.edittitle': '编辑运输商',
  'p.internaldeliverydifferencesrequest.PendingBy': '待处理服务人',
  'p.instoragedeliverydifferencesrequest.PendingBy': '待处理服务人',
  'p.abroaddeliverydifferencesrequest.PendingBy': '待处理服务人',
  'p.internaldeliverydifferencesrequest.area.resubmittitle': '补充资料',
  'p.instoragedeliverydifferencesrequest.area.resubmitinfotitle': '补充资料',
  'p.internaldeliverydifferencesrequest.area.resubmitinfotitle': '补充资料',
  'p.internaldeliverydifferencesrequest.TotalAmount': '总金额',
  'p.instoragedeliverydifferencesrequestdetail.CustomProblemType': '自定义问题描述',
  'p.internaldeliverydifferencesclaimrequest.requestno': '服务单号',
  'p.internaldeliverydifferencesclaimrequest.applicationdate': '服务提交日期',
  'p.internaldeliverydifferencesclaimrequest.shippedlocation': '发货地点',
  'p.internaldeliverydifferencesclaimrequest.deliveryDate': '收货日期',
  'p.internaldeliverydifferencesclaimrequest.PONumber': 'PO#',
  'p.internaldeliverydifferencesclaimrequest.POItem': 'Item#',
  'p.internaldeliverydifferencesclaimrequest.MaterialNo': '物料号',
  'p.internaldeliverydifferencesclaimrequest.VendorCode': '工厂代码',
  'p.internaldeliverydifferencesclaimrequest.VendorName': '工厂名称',
  'p.internaldeliverydifferencesclaimrequest.servicerequestor': '服务申请人',
  'p.internaldeliverydifferencesclaimrequest.status': '服务状态',
  'p.internaldeliverydifferencesclaimrequest.applicationtime': '服务申请时间',
  'p.internaldeliverydifferencesclaimrequest.pendingRoleName': '待处理角色',
  'p.internaldeliverydifferencesclaimrequest.detailNo': '国内收货差异单',
  'p.internaldeliverydifferencesclaimrequest.area.servicerequestbaseinfotitle': '服务申请单信息',
  'p.internaldeliverydifferencesclaimrequest.submitter': '服务提交人',
  'p.internaldeliverydifferencesclaimrequest.submittime': '服务提交日期',
  'p.internaldeliverydifferencesclaimrequest.area.servicerequestorinfotitle': '服务申请人信息',
  'p.internaldeliverydifferencesclaimrequest.servicerequestorcompany': '服务申请人公司',
  'p.internaldeliverydifferencesclaimrequest.servicerequestorphonenumber': '服务申请人电话',
  'p.internaldeliverydifferencesclaimrequest.servicerequestoremail': '服务申请人邮箱',
  'p.internaldeliverydifferencesclaimrequest.area.receiptinfotitle': '相关收货信息',
  'p.internaldeliverydifferencesclaimrequest.shippedLocation': '发货地点',
  'p.internaldeliverydifferencesclaimrequest.receivedLocation': '收货地点',
  'p.internaldeliverydifferencesclaimrequest.area.detailinfotitle': '产品相关信息',
  'p.internaldeliverydifferencesclaimrequest.area.attachmentinfotitle': '相关附件信息',
  'p.internaldeliverydifferencesclaimrequest.area.servicerequestorserviceinfotitle': '其他情况说明',
  'p.internaldeliverydifferencesclaimrequestdetail.PONumber': 'PO#',
  'p.internaldeliverydifferencesclaimrequestdetail.POItem': 'Item#',
  'p.internaldeliverydifferencesclaimrequestdetail.MaterialNo': '物料号',
  'p.internaldeliverydifferencesclaimrequestdetail.MaterialDescription': '物料描述',
  'p.internaldeliverydifferencesclaimrequestdetail.VendorCode': '工厂代码',
  'p.internaldeliverydifferencesclaimrequestdetail.VendorName': '工厂名称',
  'p.internaldeliverydifferencesclaimrequestdetail.MPN': 'MPN',
  'p.internaldeliverydifferencesclaimrequestdetail.ABCClass': 'ABC类',
  'p.internaldeliverydifferencesclaimrequestdetail.BoxingNo': '箱号',
  'p.internaldeliverydifferencesclaimrequestdetail.Quantity': '总数量',
  'p.internaldeliverydifferencesclaimrequestdetail.IssueQty': '有问题数量',
  'p.internaldeliverydifferencesclaimrequestdetail.NetPrice': 'Net Price',
  'p.internaldeliverydifferencesclaimrequestdetail.MovingPrice': 'Moving Price',
  'p.internaldeliverydifferencesclaimrequestdetail.TotalPrice': '总价值',
  'p.internaldeliverydifferencesclaimrequestdetail.Description': '具体描述',
  'p.internaldeliverydifferencesclaimrequest.serviceType': '服务分类',
  'p.internaldeliverydifferencesclaimrequestdetail.ProblemType': '责任区分',
  'p.internaldeliverydifferencesclaimrequestdetail.DifferenceType': '异常类型',
  'p.internaldeliverydifferencesclaimrequest.area.processinfotitle': '审批意见',
  'p.internaldeliverydifferencesclaimrequest.isAcceipt': '是否接受',
  'p.internaldeliverydifferencesrequest.area.vendorconfirmprocessinfotitle': 'Vendor审批意见',
  'p.abroaddeliverydifferencesrequest.LossRecognitionAmount': 'CNABB认损金额',
  'p.abroaddeliverydifferencesrequest.ForwarderPaidAmount': '货代赔付金额',
  'p.abroaddeliverydifferencesrequestdetail.ClosedTime': '解决时间',
  'p.larequestdetail.ListPriceUnit': '销售单位',
  'p.process.PlanSettlementDate': '预计解决时间',
  'p.process.PlanSolution': '预计解决方案',
  'p.process.SupportNeeded': '支持帮助',
  'p.process.plansolutiontitle': '预计解决方案',
  'p.internaldeliverydifferencesrequest.status2': '服务单状态',
  'p.internaldeliverydifferencesrequest.applicationtime': '服务提交时间',
  'p.internaldeliverydifferencesrequestdetail.Qty': '剩余问题数量',
  'p.internaldeliverydifferencesrequest.UnitPrice': 'Unit Price',
  'p.internaldeliverydifferencesrequest.MovingPrice': 'Moving Price',
  'p.internaldeliverydifferencesrequest.TotalPrice': '总价值',
  'p.internaldeliverydifferencesrequest.PlanSolution': 'Plan Solution',
  'p.internaldeliverydifferencesrequest.FinalProcessingResult': '最终处理结果',
  'p.internaldeliverydifferencesrequest.ClosedTime': '最终解决时间',
  'p.internaldeliverydifferencesrequest.pendingBy': 'Pending By',
  'p.internaldeliverydifferencesrequest.PlanSettlementDate': 'Plan Settlement Date',
  'p.internaldeliverydifferencesrequest.SupportNeeded': 'Support needed',
  'p.internaldeliverydifferencesrequest.Currency': '币种',
  'p.abroaddeliverydifferencesrequest.status2': '服务单状态',
  'p.abroaddeliverydifferencesrequest.applicationtime': '服务提交时间',
  'p.abroaddeliverydifferencesrequest.Qty': '剩余问题数量',
  'p.abroaddeliverydifferencesrequest.UnitPrice': 'Unit Price',
  'p.abroaddeliverydifferencesrequest.MovingPrice': 'Moving Price',
  'p.abroaddeliverydifferencesrequest.TotalPrice': '总价值',
  'p.abroaddeliverydifferencesrequest.PlanSolution': 'Plan Solution',
  'p.abroaddeliverydifferencesrequest.FinalProcessingResult': '最终处理结果',
  'p.abroaddeliverydifferencesrequest.ClosedTime': '最终解决时间',
  'p.abroaddeliverydifferencesrequest.pendingBy': 'Pending By',
  'p.abroaddeliverydifferencesrequest.PlanSettlementDate': 'Plan Settlement Date',
  'p.abroaddeliverydifferencesrequest.SupportNeeded': 'Support needed',
  'p.abroaddeliverydifferencesrequest.Currency': '币种',
  'p.instoragedeliverydifferencesrequest.status2': '服务单状态',
  'p.instoragedeliverydifferencesrequest.applicationtime': '服务提交时间',
  'p.instoragedeliverydifferencesrequest.Qty': '剩余问题数量',
  'p.instoragedeliverydifferencesrequest.UnitPrice': 'Unit Price',
  'p.instoragedeliverydifferencesrequest.MovingPrice': 'Moving Price',
  'p.instoragedeliverydifferencesrequest.TotalPrice': '总价值',
  'p.instoragedeliverydifferencesrequest.PlanSolution': 'Plan Solution',
  'p.instoragedeliverydifferencesrequest.FinalProcessingResult': '最终处理结果',
  'p.instoragedeliverydifferencesrequest.ClosedTime': '最终解决时间',
  'p.instoragedeliverydifferencesrequest.pendingBy': 'Pending By',
  'p.instoragedeliverydifferencesrequest.PlanSettlementDate': 'Plan Settlement Date',
  'p.instoragedeliverydifferencesrequest.SupportNeeded': 'Support needed',
  'p.instoragedeliverydifferencesrequest.Currency': '币种',
  'p.instoragedeliverydifferencesrequest.PONumber': 'PO#',
  'p.instoragedeliverydifferencesrequest.POItem': 'Item#',
  'p.instoragedeliverydifferencesrequest.MaterialDescription': '物料描述',
  'p.instoragedeliverydifferencesrequest.MG': 'MG',
  'p.instoragedeliverydifferencesrequest.MGDescription': 'MG描述',
  'p.instoragedeliverydifferencesrequest.BU': 'BU',
  'p.instoragedeliverydifferencesrequest.PG': 'PG',
  'p.instoragedeliverydifferencesrequest.SLocation': 'SLoc',
  'p.deliveryDifferencesrequest.type': '差异来源',
  'p.process.IsSupportNeeded': '是否需要帮助',
  'p.deliverydifferencessummary.PendingRole': '负责团队',
  'p.deliverydifferencessummary.PendingBy': '负责人',
  'p.deliverydifferencessummary.ProblemType': '责任区分',
  'p.deliverydifferencessummary.DeliveryNoteNo': '运单号',
  'p.deliverydifferencessummary.ClaimPrice': '索赔金额',
  'p.deliverydifferencessummary.ClaimCurrency': '索赔币种',
  'p.deliverydifferencessummary.NCRNumber': "NCR#",
  'p.internaldeliverydifferencesrequest.TotalPrice2': '差异总价值',
  'p.internaldeliverydifferencesclaimrequest.area.internaldeliverydifferencesinfotitle': '差异申请单信息',
  'p.internaldeliverydifferencesrequest.IsReturnFactory': '是否返厂',
  'p.instoragedeliverydifferencesrequestdetail.MPN': 'MPN#',
  'p.instoragedeliverydifferencesrequestdetail.ABCIndicator': 'ABC Indicator',
  'p.instoragedeliverydifferencesrequestdetail.DifferentialValue': '差异总价值',
  'p.internaldeliverydifferencesrequestdetail.MPN': 'MPN#',
  'p.internaldeliverydifferencesrequestdetail.ABCIndicator': 'ABC Indicator',
  'p.internaldeliverydifferencesrequestdetail.IsDamaged': '运输包装是否破损',
  'p.internaldeliverydifferencesrequestdetail.IsDamp': '运输包装是否潮湿',
  'p.instoragedeliverydifferencesrequestdetail.MG': 'MG',
  'p.instoragedeliverydifferencesrequestdetail.MGDescription': 'MG描述',
  'p.instoragedeliverydifferencesrequest.batchUpload': '批量更新解决方案',

  'p.tssphazard.description':'每年SPE要输入两次隐患报告申报.',
  'p.tssphazard.requestNo':'编号',
  'p.tssphazard.applicant':'SPE名称',
  'p.tssphazard.applicantEmail':'SPE邮箱',
  'p.tssphazard.spName':'SPE所属公司',
  'p.tssphazard.createdDate':'创建时间',
  'p.tssphazard.applicationDate':'提交时间',
  'p.tssphazard.hazardLocation':'隐患发生地点',
  'p.tssphazard.hazardTime':'隐患发生时间',
  'p.tssphazard.hazardType':'隐患类别',
  'p.tssphazard.projectname':'工程项目名称',
  'p.tssphazard.hazardDescription':'隐患描述（请详细说明隐患所在区域位置，以及告知的相关联系人）',
  'p.tssphazard.hazardImmediateAction':'立即采取的行动',
  'p.tssphazard.remark':'备注',
  'p.tssphazard.attachment':'相关照片',
  'p.tssphazard.hazardIsSolve':'隐患是否解决',
  'p.tssphazard.solveRemark':'隐患是否解决（否）',
  'p.tssphazard.status':'状态',
  'p.tsrepairrequest.repairno': '返修单号',
  'p.tsrepairrequest.applicationdate': '返修申请日期',
  'p.tsrepairrequest.requestor': '返修申请人',
  'p.tsrepairrequest.relatedabbdistributor': 'ABB客户',
  'p.tsrepairrequest.shipfrom': '出货地点',
  'p.tsrepairrequest.area.baseinfotitle': '客户信息',
  'p.tsrepairrequest.servicerequestor': '服务申请人',
  'p.tsrepairrequest.servicerequestorphonenumber': '服务申请人电话',
  'p.tsrepairrequest.servicecontactperson': '服务现场联系人',
  'p.tsrepairrequest.servicecontactpersonphonenumber': '服务现场联系电话',
  'p.tsrepairrequest.servicecontactpersoncompany': '服务联系人公司',
  'p.tsrepairrequest.abbdistributor': 'ABB客户',
  'p.tsrepairrequest.tsownerphonenumber': 'TS负责人联系电话',
  'p.tsrepairrequest.area.receiptinfotitle': '客户收货信息',
  'p.tsrepairrequest.useshippedtoparty': 'ShipToParty',
  'p.tsrepairrequest.shippedpartyno': 'ShiptoParty No',
  'p.tsrepairrequest.shippedtocompany': '收货单位',
  'p.tsrepairrequest.shippedtocity': '收货地城市',
  'p.tsrepairrequest.postalcode': '收货邮编',
  'p.tsrepairrequest.shippedtoperson': '收货人',
  'p.tsrepairrequest.shippedtocontactno': '联系电话',
  'p.tsrepairrequest.shippedtoaddress': '收货地址',
  'p.tsrepairrequest.area.detailinfotitle': '返修产品信息',
  'p.tsrepairrequest.materialno': '物料号',
  'p.tsrepairrequest.description': '产品型号',
  'p.tsrepairrequest.mpn': 'MPN',
  'p.tsrepairrequest.listprice': '物料面价',
  'p.tsrepairrequest.vendorcode': '工厂代码',
  'p.tsrepairrequest.vendorname': '工厂名称',
  'p.tsrepairrequest.servicetag': '序列号',
  'p.tsrepairrequest.qty': '数量',
  'p.tsrepairrequest.diviationcomments': '差异原因',
  'p.tsrepairrequest.applyreason': '申请原因',
  'p.tsrepairrequest.area.tsrequestinfotitle': '服务单信息',
  'p.tsrepairrequest.tsrequestno': '服务单号',
  'p.tsrepairrequest.tsrequestapplicationdate': '服务提交日期',
  'p.tsrepairrequest.totalcost': '总金额',
  'p.tsrepairrequest.selectproducttitle': '选择产品',
  'p.tsrepairrequest.area.tsreplacemeninfotitle': '申请单信息',
  'p.tsrepairrequest.status': '状态',
  'p.tsrepairrequest.applicant': '返修申请人',
  'p.tsrepairrequest.currency': '币种',
  'p.tsrepairrequest.area.processinfotitle': '审批意见',
  'p.tsrepairrequest.area.approvalloginfotitle': '审批日志信息',
  'p.tsrepairrequest.area.vendorconfirminfotitle': '处理服务',
  'p.tsrepairrequest.transportnumber': '货运单号',
  'p.tsrepairrequest.transportcompany': '货运公司',
  'p.tsrepairrequest.approvalremark': '处理意见',
  'p.tsrepairrequest.amount': '金额',
  'p.tsrepairrequest.cancelrequesttitle': '撤销申请',
  'p.tsrepairrequest.lastapprover': '最后审批人',
  'p.tsrepairrequest.lastapprovedate': '最后审批时间',
  'p.tsrepairrequest.tsreturnStatus': '退货单状态',
  'p.tsrepairrequest.isTureReturn': '是否正常退货',
  'p.tsrepairrequest.shippedtocitytip': '输入“北京”，不是“北京市”',
  'p.tsrepairrequest.repairtype':'申请分类',
  'p.tsrepairrequest.completedtime': '审批完成时间',
  'p.tsrepairrequest.area.shippinginfotitle':'返修货单信息',
  'p.tsrepairrequest.isreturn': '是否已退回',
  'p.tsrepairrequest.area.detectioninfotitle':'检测信息',
  'p.tsrepairrequest.detectionDetail': '检测结果',
  'p.tsrepairrequest.detectionUser': '检测人',
  'p.tsrepairrequest.detectionDate': '检测日期',
  'p.tsrepairrequest.area.detectionrteport':'检测报告',
  'p.tsrepairrequest.reportattachment':'附件',
  'p.tsrepairrequest.reportUser': '报告人',
  'p.tsrepairrequest.reportDate': '报告日期',
  'p.tsrepairrequest.shareReport': '分享报告',
  'p.tsrequest.tsrepairrequestlist': '返修记录',
  'p.tsrepairrequest.area.vendorprocessinfotitle': '工厂审批意见',
  'p.tsrepairrequest.area.vendorreceipt':'工厂收货',
  'p.tsrepairrequest.actualqty':'实收数量',
  'p.tsrepairrequest.actualmaterial':'实收物料描述',
  'p.tsrepairrequest.differenceReason':'差异原因',
  'p.tsrepairrequest.shippedtoovendor':'收货工厂',
  'p.lareturnrequest.attachment':'附件',
  'p.tsrepairrequest.remark':'备注信息',
  'p.tsrepairrequest.table.attachmentName':'附件名称',
  'p.tsrepairrequest.table.isShareDisplayName':'是否分享',
  'p.tsrepairrequest.table.createdBy':'提交人',
  'p.tsrepairrequest.table.createdDateDisplayName':'提交日期',
  'p.tsrepairrequest.table.actions':'操作',
  'p.tsrepairrequest.vendorrejectedrequesttitle': '拒绝原因',
  'p.tsrepairrequest.mgdescription': 'MG描述',
  'p.tsrepairrequest.detectionStatusDisplayName':'检测状态',
  'p.tsrepairrequest.shareReportDate': '分享报告日期',

};
export default messages;
