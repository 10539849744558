import http from './http';

export function get(id) {
  return http.get('/TSReturnRequest/Get?id=' + id);
}

export function getBasicInfo(tsReplacementRequestID) {
  return http.get(
    '/TSReturnRequest/GetBasicInfo?tsReplacementRequestID=' +
      tsReplacementRequestID
  );
}

export function getUnReturnCount() {
  return http.get('/TSReturnRequest/GetUnReturnCount');
}

export function getToDoCount() {
  return http.get('/TSReturnRequest/GetToDoCount');
}

export function confirmReceipt(data) {
  return http.post('/TSReturnRequest/ConfirmReceipt', data);
}

export function closeReturn(data) {
  return http.post('/TSReturnRequest/CloseReturn', data);
}

export function save(data) {
  return http.post('/TSReturnRequest/Save', data);
}

export function submit(data) {
  return http.post('/TSReturnRequest/Submit', data);
}

export function del(id) {
  return http.delete('/TSReturnRequest/Delete?id=' + id);
}

export function getUnReturnPagingList(query) {
  return http.post('/TSReturnRequest/GetUnReturnPagingList', query);
}

export function exportUnReturnPagingList(query) {
  return http.post('/TSReturnRequest/ExportUnReturnPagingList', query);
}

export function getToDoPagingList(query) {
  return http.post('/TSReturnRequest/GetToDoPagingList', query);
}

export function getReportPagingList(query) {
  return http.post('/TSReturnRequest/GetReportPagingList', query);
}

export function exportReportData(query) {
  return http.post('/TSReturnRequest/ExportReportData', query);
}
