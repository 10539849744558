import http from './http';

export function getPagingList(query) {
  return http.get('/TSRequest/GetPagingList', query);
}

export function getDraftRequestCount() {
  return http.get('/TSRequest/GetDraftRequestCount');
}

export function get(id, type) {
  return http.get('/TSRequest/Get?id=' + id + '&type=' + type);
}

export function getToDoCount() {
  return http.get('/TSRequest/GetToDoCount');
}

export function save(data) {
  return http.post('/TSRequest/Save', data);
}

export function update(data) {
  return http.post('/TSRequest/Update', data);
}
export function updateServiceRequestor(data) {
  return http.post('/TSRequest/UpdateServiceRequestor', data);
}
export function addServiceRequestItem(data) {
  return http.post('/TSRequest/AddServiceRequestItem', data);
}

export function updateServiceRequestItem(data) {
  return http.post('/TSRequest/UpdateServiceRequestItem', data);
}

export function deleteServiceRequestItem(data) {
  return http.post('/TSRequest/DeleteServiceRequestItem', data);
}

export function submit(data) {
  return http.post('/TSRequest/Submit', data);
}

export function del(id) {
  return http.delete('/TSRequest/Delete?id=' + id);
}

export function cancel(id) {
  return http.get('/TSRequest/Cancel?id=' + id);
}

export function withdraw(id) {
  return http.get('/TSRequest/Withdraw?id=' + id);
}

export function assignTS(data) {
  return http.post('/TSRequest/AssignTS', data);
}

export function reAssignTS(data) {
  return http.post('/TSRequest/ReAssignTS', data);
}

export function tsClaim(data) {
  return http.post('/TSRequest/TSClaim', data);
}

export function changeServiceOffice(data) {
  return http.post('/TSRequest/ChangeServiceOffice', data);
}

export function completeService(data) {
  return http.post('/TSRequest/CompleteService', data);
}

export function getToDoPagingList(query) {
  return http.post('/TSRequest/GetToDoPagingList', query);
}

export function getReportPagingList(query) {
  return http.post('/TSRequest/GetReportPagingList', query);
}

export function getDetailReportPagingList(query) {
  return http.post('/TSRequest/GetDetailReportPagingList', query);
}

export function getDashBoardReportPagingList(query) {
  return http.post('/TSRequest/GetDashBoardReportPagingList', query);
}

export function getDraftPagingList(query) {
  return http.post('/TSRequest/GetDraftPagingList', query);
}

export function assignSP(data) {
  return http.post('/TSRequest/assignSP', data);
}

export function spAssignConfirm(tsid) {
  return http.post('/TSRequest/SPAssignConfirm?TSID=' + tsid);
}

export function exportReportData(query) {
  return http.post('/TSRequest/ExportReportData', query);
}
export function exportDetailReportData(query) {
  return http.post('/TSRequest/ExportDetailReportData', query);
}

export function getOnsiteSupportLogList(id) {
  return http.post('/TSRequest/GetOnsiteSupportLogList?id=' + id);
}
export function GetTSOnsiteSupportTaskList(id, spCode) {
  return http.post(
    '/TSRequest/GetTSOnsiteSupportTaskList?id=' + id + '&spCode=' + spCode
  );
}
