import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { setRefreshToken } from '@/api/auth';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// Local Dev Cookie Setting
// Dev,QA 和 PRD 环境请删除 下列的配置
// https://dev.cloudapps02.abb.com.cn/9aag034262
// const cookieVal = `XjTA3LqrZQpeTzQ8pNKGy0JHQqBZPFC2k4ijnF5SFMUTzsAgbM1p9VlkTjc2dhrIfRzTFhk9w3kC6oMd80eZHt0DB9czNnIX1YCLox3chwzXew48mqiuIXCGPTypWFFZ38UywMFzplfJoOR1jnDOTJTibWgC9JpnUnmZq3LGBWsNGrkefzpH9sL6VIWdTmNc4pc6OIJ4IBCsDoIc6RznvB8`;
// setRefreshToken(cookieVal);
//

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
