const messages = {
  'b.login': '登录',
  'b.abbemaillogin': 'ABB邮箱登录',
  'b.search': '搜索',
  'b.save': '保存',
  'b.submit': '提交',
  'b.cancel': '取消',
  'b.returnback': '退回',
  'b.upload': '上传',
  'b.additem': '添加',
  'b.add': '新建',
  'b.edit': '编辑',
  'b.delete': '删除',
  'b.yes': '是',
  'b.no': '否',
  'b.export': '导出',
  'b.exportPDF': '导出PDF',
  'b.import': '导入',
  'b.hsechek': '作业许可',
  'b.assignts': '指派TS',
  'b.assignsp': '指派SP',
  'b.reassignsp': '重新指派SP',
  'b.replenishment': '批量补换货',
  'b.changeserviceoffice': '修改服务所在地办公室',
  'b.claimtsrequest': '认领服务单',
  'b.rejectclaimtsrequest': '拒绝服务单',
  'b.updatetsrequest': '修改服务单',
  'b.reassignts': '重新指派TS',
  'b.completeservice': '关闭服务',
  'b.technicaladvice': '技术建议',
  'b.detail': '详情',
  'b.workflowchart': '流程图',
  'b.selectproduct': '选择产品',
  'b.addproduct': '添加产品',
  'b.approve': '同意',
  'b.reject': '拒绝',
  'b.return': '退货',
  'b.awaitconfirm': '待确认',
  'b.onsite.return': '退回',
  'b.completedlog': '完成日志',
  'b.approval': '审批',
  'b.receipt': '确认收货',
  'b.receipt.amount': '确认费用',
  'b.confirm': '确认',
  'b.spassignconfirm': '接受指派',
  'b.startcheck': '作业许可',
  'b.closecheck': '完工检查',
  'b.onenlink': '打开链接',
  'b.receipt.replenish': '确认收货-工厂补货',
  'b.receipt.materialhandle': '确认实物处理方式',
  'b.repair': '返修申请',
  'b.freereplenish': '免费补货',
  'b.check': '校验',
  'b.sparrow': 'Sparrow',
  'b.applyexpense': '申请报销',
  'b.cancelrequest': '撤销申请',
  'b.copy': '复制申请单',
  'b.createsaporder': '创建SAP换货单',
  'b.closereplenish': '关闭补货',
  'b.onsite.cancel': '撤销作业许可',
  'b.download': '下载',
  'b.downloadtemplete': '下载模板',
  'b.downloadall': '批量下载',
  'b.more': '更多',
  'b.reset': '重置',
  'b.closereturn': '关闭退货',
  'b.viewattachments': '查看附件',
  'b.reexecute': '重新执行',
  'b.edituserinfo': '修改用户信息',
  'b.batchsolve': '批量解决',
  'b.batchcomplete': '批量完成',
  'b.print': '打印',
  'b.plansolution': '预计解决方案',
  'b.close': '关闭',
  'b.handle': '处理',
  'b.batchUpload': '批量更新',
  'b.back': '返回',
  'b.share': '确认分享',
  'b.rejectreturn':'拒绝退货',
  'b.receipt.reject':'拒绝收货',
  'b.receipt.submitreport':'提交报告',
  'b.receipt.rejectreport':'拒绝报告',

  'b.receipt.receiptreport': '确认报告',
  'b.repair.return': '回退修改',
  'b.repair.share': '分享',
  'b.repair.unshare': '取消分享',

};
export default messages;
