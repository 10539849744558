import http from './http';

export function GetNotExpensesTodoPagingListAsync(data) {
  return http.post('/OnsiteExpenses/GetNotExpensesTodoPagingList', data);
}
export function Submit(data) {
  return http.post('/OnsiteExpenses/Submit', data);
}
export function Get(ID) {
  return http.post('/OnsiteExpenses/Get?ID=' + ID);
}
export function GetPendingListAsync(data) {
  return http.post('/OnsiteExpenses/GetPendingList', data);
}
export function ProcessConfirm(data) {
  return http.post('/OnsiteExpenses/ProcessConfirm', data);
}
export function GetReportListAsync(data) {
  return http.post('/OnsiteExpenses/GetReportList', data);
}
export function GetExportListAsync(data) {
  return http.post('/OnsiteExpenses/GetExportList', data);
}
export function GetNotExpensesTodoPagingCount() {
  return http.get('/OnsiteExpenses/GetNotExpensesTodoPagingCount');
}
export function GetPendingCountAsync() {
  return http.get('/OnsiteExpenses/GetPendingCount');
}
