import { useSelector } from 'react-redux';
import {
  selectMenus,
  selectStatus,
  selectRoles,
  AUTH_STATUS,
} from '../features/auth/authSlice';
import { Redirect } from 'react-router-dom';
import HttpError from './httperror/HttpError';
import LayoutDefault from './layout/LayoutDefault';

/*
如果未登录，跳转至登录页面
如果已登录，跳转至第一个菜单
如果已登录，无菜单，显示403错误
*/
const RedirectDefault = () => {
  const status = useSelector(selectStatus);
  const menus = useSelector(selectMenus);
  const roles = useSelector(selectRoles);
  let url;
  console.log('status0:' + status);
  if (status === AUTH_STATUS.SUCCEEDED) {
    if (menus && menus.length > 0) {
      if (roles.indexOf('TS') > -1) {
        url = 'tsrequest/todo';
      } else {
        url = getDefaultFromMenus(menus);
      }
      console.log('default url:' + url);
    } else {
      return (
        <HttpError
          code={403}
          home={false}
          layout={menus && menus.length > 0 ? LayoutDefault : null}
        />
      );
    }
  } else {
    url = '/signin';
  }
  return <Redirect to={url} />;
};
export default RedirectDefault;

function getDefaultFromMenus(menus) {
  let url;
  for (let menu of menus) {
    if (menu.url) {
      url = menu.url;
      break;
    } else if (menu.children && menu.children.length > 0) {
      url = getDefaultFromMenus(menu.children);
      if (url) {
        break;
      }
    }
  }
  console.log('default url:' + url);
  return url;
}
