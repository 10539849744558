import http from './http';

export function getPagingList(query) {
  return http.get('/InStorageDeliveryDifferences/GetPagingList', query);
}

export function getDraftRequestCount() {
  return http.get('/InStorageDeliveryDifferences/GetDraftRequestCount');
}

export function get(id, type) {
  return http.get('/InStorageDeliveryDifferences/Get?id=' + id + '&type=' + type);
}

export function getByDetailId(id) {
  return http.get('/InStorageDeliveryDifferences/GetByDetailId?id=' + id);
}

export function getPlanSolution(id) {
  return http.get('/InStorageDeliveryDifferences/GetPlanSolution?id=' + id);
}

export function getBasicInfo() {
  return http.get('/InStorageDeliveryDifferences/GetBasicInfo');
}

export function getToDoCount() {
  return http.get('/InStorageDeliveryDifferences/GetToDoCount');
}

export function save(data) {
  return http.post('/InStorageDeliveryDifferences/Save', data);
}

export function update(data) {
  return http.post('/InStorageDeliveryDifferences/Update', data);
}

export function submit(data) {
  return http.post('/InStorageDeliveryDifferences/Submit', data);
}

export function del(id) {
  return http.delete('/InStorageDeliveryDifferences/Delete?id=' + id);
}

export function buyerHandling(data) {
  return http.post('/InStorageDeliveryDifferences/BuyerHandling', data);
}

export function saveBuyerHandling(data) {
  return http.post('/InStorageDeliveryDifferences/SaveBuyerHandling', data);
}

export function mpHandling(data) {
  return http.post('/InStorageDeliveryDifferences/MPHandling', data);
}

export function saveMPHandling(data) {
  return http.post('/InStorageDeliveryDifferences/SaveMPHandling', data);
}

export function warehouseConfirm(data) {
  return http.post('/InStorageDeliveryDifferences/WarehouseConfirm', data);
}

export function reSubmit(data) {
  return http.post('/InStorageDeliveryDifferences/ReSubmit', data);
}

export function standardReturn(data) {
  return http.post('/InStorageDeliveryDifferences/StandardReturn', data);
}

export function standardReject(data) {
  return http.post('/InStorageDeliveryDifferences/StandardReject', data);
}

export function savePlanSolution(data) {
  return http.post('/InStorageDeliveryDifferences/SavePlanSolution', data);
}

export function getToDoPagingList(query) {
  return http.post('/InStorageDeliveryDifferences/GetToDoPagingList', query);
}

export function getDetailReportPagingList(query) {
  return http.post('/InStorageDeliveryDifferences/GetDetailReportPagingList', query);
}

export function exportDetailReportData(query) {
  return http.post('/InStorageDeliveryDifferences/ExportDetailReportData', query);
}

export function getReportPagingList(query) {
  return http.post('/InStorageDeliveryDifferences/GetReportPagingList', query);
}

export function getDraftPagingList(query) {
  return http.post('/InStorageDeliveryDifferences/GetDraftPagingList', query);
}

export function exportReportData(query) {
  return http.post('/InStorageDeliveryDifferences/ExportReportData', query);
}

