import axios from 'axios';
import { debugEnable } from '@/utils/utils';
import { getLocale } from '@/locales';
import { getRefreshToken, refreshToken } from './auth';
import * as LoginService from '@/api/login';
import { Redirect } from 'react-router-dom';
import {
  notification,
} from 'antd';
import { handleError } from '@/utils/handleError';

const instance = axios.create({
  baseURL: window.ENV_CFG.api_root + '/api',
  timeout: 120000,
  headers: { 'Accept-Language': getLocale() },
});

// Add a request interceptor
instance.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    // Do something with request error
    //if (debugEnable) console.log('[http]request error:', error);
    return Promise.reject({ status: 400 });
    //const res = JSON.parse(JSON.stringify(error.response));
    //return Promise.reject(res);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (debugEnable)
      console.log('[http]response error:', error, error.response);

    if (error.response) {
      if (error.response.status === 401 && !error.response.config.isretry) {
        return refreshToken(getRefreshToken())
          .then(res => {
            if (res.access_token) {
              error.response.config.isretry = true;
              error.response.config.headers[
                'Authorization'
              ] = `${res.token_type} ${res.access_token}`;
              return instance.request(error.response.config);
            } else {
              return Promise.reject({
                status: 401,
                data: 'refresh_token_error',
              });
            }
          })
          .catch(e => {
            return Promise.reject({
              status: 401,
              data: 'refresh_token_error',
            });
          });
      }else if(error.response.status === 999){
        // var url = '#/signin';
        // return window.location.href=url;
        LoginService.logout()
        .then(res => {
          debugger;
          if (res.tag === 1) {
            //window.location.reload();
            //var url = '#/signin';
            //return window.location.href=url;
            window.localStorage.clear();
            notification.error({
              message: "超过30分钟无操作，请重新登录",
              placement: 'top',
              duration: 0,
            });
            window.location.reload();
            //return Promise.reject({ status: 0 });
          } else {
            notification.error({
              message: res.message,
              placement: 'top',
              duration: 0,
            });
          }
        })
        .catch(e => {
          handleError(e);
        });
      }else {
        const res = JSON.parse(JSON.stringify(error.response));
        return Promise.reject(res);
      }
    } else {
      return Promise.reject({ status: 0 });
    }
  }
);

const http = {
  instance: instance,
  get(url, params) {
    return instance.get(url, { params });
  },
  post(url, data, config) {
    return instance.post(url, data, config);
  },
  delete(url, params) {
    return instance.delete(url, { params });
  },
  setAuthorization(token) {
    instance.defaults.headers.common[
      'Authorization'
    ] = `${token.token_type} ${token.access_token}`;
  },
};

export default http;
