import http from './http';

export function get(id) {
  return http.get('/InternalDeliveryDifferencesClaim/Get?id=' + id);
}

export function getToDoCount() {
  return http.get('/InternalDeliveryDifferencesClaim/GetToDoCount');
}

export function getToDoPagingList(query) {
  return http.post('/InternalDeliveryDifferencesClaim/GetToDoPagingList', query);
}

export function getReportPagingList(query) {
  return http.post('/InternalDeliveryDifferencesClaim/GetReportPagingList', query);
}

export function exportReportData(query) {
  return http.post('/InternalDeliveryDifferencesClaim/ExportReportData', query);
}

export function vendorHandling(data) {
  return http.post('/InternalDeliveryDifferencesClaim/VendorHandling', data);
}

