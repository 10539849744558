import http from './http';

export function get(id, type) {
  return http.get('/TSRepairRequest/Get?id=' + id + '&type=' + type);
}

export function getList(tsRequestID) {
  return http.get('/TSRepairRequest/GetList?tsRequestID=' + tsRequestID);
}

export function getBasicInfo(tsRequestID) {
  return http.get(
    '/TSRepairRequest/GetBasicInfo?tsRequestID=' + tsRequestID
  );
}

export function getDetailList(id) {
  return http.get('/TSRepairRequest/GetDetailList?id=' + id);
}

export function getToDoCount() {
  return http.get('/TSRepairRequest/GetToDoCount');
}

export function getResponsibleOHInfo(customerCode, salesOfficeCode) {
  return http.get(
    '/TSRepairRequest/GetResponsibleOHInfo?customerCode=' +
    customerCode +
    '&salesOfficeCode=' +
    salesOfficeCode
  );
}

export function checkIfApplied(tsDetailID) {
  return http.get(
    '/TSRepairRequest/CheckIfApplied?tsDetailID=' + tsDetailID
  );
}

export function save(data) {
  return http.post('/TSRepairRequest/Save', data);
}

export function submit(data) {
  return http.post('/TSRepairRequest/Submit', data);
}

export function closce(data) {
  return http.post('/TSRepairRequest/Close', data);
}

export function cancel(data) {
  return http.post('/TSRepairRequest/Cancel', data);
}

export function approval(data) {
  return http.post('/TSRepairRequest/Approval', data);
}

export function tsShipping(data) {
  return http.post('/TSRepairRequest/TSShipping', data);
}
export function vendorReceipt(data) {
  return http.post('/TSRepairRequest/VendorReceipt', data);
}
export function vendorDetection(data) {
  return http.post('/TSRepairRequest/VendorDetection', data);
}
export function vendorReport(data) {
  return http.post('/TSRepairRequest/VendorReport', data);
}
export function vendorRejectReceipt(data) {
  return http.post('/TSRepairRequest/VendorRejectReceipt', data);
}
export function del(id) {
  return http.delete('/TSRepairRequest/Delete?id=' + id);
}
export function tsApproval(data) {
  return http.post('/TSRepairRequest/TSApproval', data);
}
export function tsShare(data) {
  return http.post('/TSRepairRequest/TSShare', data);
}
export function TSConfirm(data) {
  return http.post('/TSRepairRequest/TSConfirm', data);
}

export function DeleteAttachment(id) {
  return http.delete('/TSRepairRequest/DeleteAttachment?AttachmentID=' + id);
}
export function GetAttachmentList(detailID) {
  return http.post('/TSRepairRequest/GetAttachmentList?detailID=' + detailID);
}
export function HandleChangeShareAttachment(TSRepairAttachmentID) {
  return http.post('/TSRepairRequest/HandleChangeShareAttachment?TSRepairAttachmentID=' + TSRepairAttachmentID);
}

export function getToDoPagingList(query) {
  return http.post('/TSRepairRequest/GetToDoPagingList', query);
}

export function getReportPagingList(query) {
  return http.post('/TSRepairRequest/GetReportPagingList', query);
}

export function exportReportData(query) {
  return http.post('/TSRepairRequest/ExportReportData', query);
}
