import http from './http';

export function getToDoPagingList(data) {
  return http.post('/GoodsClaim/getToDoPagingList', data);
}
export function GetToDoCount(data) {
  return http.get('/GoodsClaim/GetToDoCount', data);
}
export function getGoodsClaim(id) {
  return http.post('/GoodsClaim/GetGoodsClaim?id=' + id);
}
export function handleBuyerOrVendorConfirm(dto) {
  return http.post('/GoodsClaim/BuyerOrVendorConfirm', dto);
}
export function handleVendorRejectBuyerConfirm(dto) {
  return http.post('/GoodsClaim/VendorRejectBuyerConfirm', dto);
}
export function handleWHConfirm(dto) {
  return http.post('/GoodsClaim/WHConfirm', dto);
}
export function getRepostList(data) {
  return http.post('/GoodsClaim/getRepostList', data);
}
export function getRepostExportList(data) {
  return http.post('/GoodsClaim/getRepostExportList', data);
}
export function GetLARequestClaimList(id) {
  return http.post('/GoodsClaim/GetLARequestClaimList?LARequestID=' + id);
}
export function GetLARequestClaimListByDetailId(id) {
  return http.post(
    '/GoodsClaim/GetLARequestClaimListByDetailId?LARequestDetailID=' + id
  );
}
