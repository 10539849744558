import http from './http';

export function getLoginInfo() {
  return http.get('/Account/GetLoginUserInfo');
}

export function getCurrentCBOLUserInfo() {
  return http.get('/Account/GetCurrentCBOLUserInfo');
}

export function getTSUserList(serviceOfficeCode) {
  return http.get(
    '/Account/GetTSUserList?serviceOfficeCode=' + serviceOfficeCode
  );
}

export function getUserSelectList(data) {
  return http.post('/Account/GetUserSelectList', data);
}
