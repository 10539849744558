import http from './http';

export function get(id) {
  return http.get('/LAReplacementRequest/Get?id=' + id);
}

export function getList(laRequestID) {
  return http.get('/LAReplacementRequest/GetList?laRequestID=' + laRequestID);
}

export function getListByDetailId(laRequestDetailID) {
  return http.get(
    '/LAReplacementRequest/GetListByDetailId?laRequestDetailID=' +
      laRequestDetailID
  );
}

export function getBasicInfo(laRequestID) {
  return http.get(
    '/LAReplacementRequest/GetBasicInfo?laRequestID=' + laRequestID
  );
}

export function getDetailList(id) {
  return http.get('/LAReplacementRequest/GetDetailList?id=' + id);
}

export function getToDoCount() {
  return http.get('/LAReplacementRequest/GetToDoCount');
}

export function closeReplenish(data) {
  return http.post('/LAReplacementRequest/CloseReplenish', data);
}

export function close(data) {
  return http.post('/LAReplacementRequest/Close', data);
}

export function save(data) {
  return http.post('/LAReplacementRequest/Save', data);
}

export function submit(data) {
  return http.post('/LAReplacementRequest/Submit', data);
}

export function createSAPOrder(id) {
  return http.post('/LAReplacementRequest/CreateSAPOrder?id=' + id);
}

export function del(id) {
  return http.delete('/LAReplacementRequest/Delete?id=' + id);
}

export function cancel(data) {
  return http.post('/LAReplacementRequest/Cancel', data);
}

export function approval(data) {
  return http.post('/LAReplacementRequest/Approval', data);
}

export function getToDoPagingList(query) {
  return http.post('/LAReplacementRequest/GetToDoPagingList', query);
}

export function getReportPagingList(query) {
  return http.post('/LAReplacementRequest/GetReportPagingList', query);
}

export function exportReportData(query) {
  return http.post('/LAReplacementRequest/ExportReportData', query);
}
