import http from './http';

export function getToDoCount() {
  return http.get('/LARequestDetail/GetToDoCount');
}

export function getUnReplacementCount() {
  return http.get('/LARequestDetail/GetUnReplacementCount');
}

export function getList(data) {
  return http.post('/LARequestDetail/GetList', data);
}

export function confirm(data) {
  return http.post('/LARequestDetail/Confirm', data);
}

export function whConfirm(data) {
  return http.post('/LARequestDetail/WHConfirm', data);
}

export function whDMConfirm(data) {
  return http.post('/LARequestDetail/WHDMConfirm', data);
}

export function tmcConfirm(data) {
  return http.post('/LARequestDetail/TMCConfirm', data);
}
export function tmcReturn(data) {
  return http.post('/LARequestDetail/TMCReturn', data);
}

export function cbolSubmit(data){
  return http.post('/LARequestDetail/cbolSubmit', data);
}

export function tmConfirm(data) {
  return http.post('/LARequestDetail/TMConfirm', data);
}

export function tmDMConfirm(data) {
  return http.post('/LARequestDetail/TMDMConfirm', data);
}

export function buyerConfirm(data) {
  return http.post('/LARequestDetail/BuyerConfirm', data);
}

export function getToDoPagingList(query) {
  return http.post('/LARequestDetail/GetToDoPagingList', query);
}

export function getUnReplacementPagingList(query) {
  return http.post('/LARequestDetail/GetUnReplacementPagingList', query);
}

export function getWDReportPagingList(query) {
  return http.post('/LARequestDetail/GetWDReportPagingList', query);
}

export function exportWDReportData(query) {
  return http.post('/LARequestDetail/ExportWDReportData', query);
}

export function getDMReportPagingList(query) {
  return http.post('/LARequestDetail/GetDMReportPagingList', query);
}

export function exportDMReportData(query) {
  return http.post('/LARequestDetail/ExportDMReportData', query);
}

export function getReportPagingList(query) {
  return http.post('/LARequestDetail/GetReportPagingList', query);
}

export function getDashBoardReportPagingList(query) {
  return http.post('/LARequestDetail/GetDashBoardReportPagingList', query);
}
