import http from './http';

export function getPagingList(query) {
  return http.get('/LARequest/GetPagingList', query);
}

export function getDraftRequestCount() {
  return http.get('/LARequest/GetDraftRequestCount');
}

export function get(id, type) {
  return http.get('/LARequest/Get?id=' + id + '&type=' + type);
}

export function getByDetailId(id) {
  return http.get('/LARequest/GetByDetailId?id=' + id);
}

export function getBasicInfo() {
  return http.get('/LARequest/GetBasicInfo');
}

export function getResponsibleOHInfo(salesOfficeCode) {
  return http.get(
    '/LARequest/GetResponsibleOHInfo?salesOfficeCode=' + salesOfficeCode
  );
}

export function getReceivedLocationSelectList(salesOfficeCode) {
  return http.get(
    '/LARequest/GetReceivedLocationSelectList?salesOfficeCode=' +
      salesOfficeCode
  );
}

export function getToDoCount() {
  return http.get('/LARequest/GetToDoCount');
}

export function save(data) {
  return http.post('/LARequest/Save', data);
}

export function update(data) {
  return http.post('/LARequest/Update', data);
}

export function ohConfirm(data) {
  return http.post('/LARequest/OHConfirm', data);
}

export function submit(data) {
  return http.post('/LARequest/Submit', data);
}

export function del(id) {
  return http.delete('/LARequest/Delete?id=' + id);
}

export function cancel(id) {
  return http.get('/LARequest/Cancel?id=' + id);
}

export function getToDoPagingList(query) {
  return http.post('/LARequest/GetToDoPagingList', query);
}

export function getReportPagingList(query) {
  return http.post('/LARequest/GetReportPagingList', query);
}

export function getDraftPagingList(query) {
  return http.post('/LARequest/GetDraftPagingList', query);
}

export function exportReportData(query) {
  return http.post('/LARequest/ExportReportData', query);
}
