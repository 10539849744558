import http from './http';

export function getValidateCodeImage() {
  return http.get('/Login/GetValidateCodeImage?t=' + new Date());
}

export function login(data) {
  return http.post('/Login/Login', data);
}

export function sSOLogin(data) {
  return http.post('/Login/SSOLogin', data);
}
export function onSiteSupprotLogin(data) {
  return http.post('/Login/OnSiteSupprotLogin', data);
}

export function logout() {
  return http.get('/Login/LogOut');
}
