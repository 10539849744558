import { lazy } from 'react';
//import SideMenu from '../components/SideMenu';
import LayoutDefault from '../components/layout/LayoutDefault';

// auth: 验证权限
// menu: 菜单，如果为空与auth一致
// anonymous: 默认false
// layout: 默认LayoutDefault
// exact: 是否精确匹配
// routes: 子路由
const routes = [
  {
    auth: 'home',
    path: '/home',
    component: lazy(() => import('../features/home/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'cboluserdashboard',
    path: '/dashboard/cbol',
    component: lazy(() => import('../features/dashboard/cbol/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'newtsrequesttspage',
    path: '/tsrequest/ts/create',
    component: lazy(() => import('../features/tsrequest/ts/new/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'updatetsrequesttspage',
    path: '/tsrequest/ts/edit/:id',
    component: lazy(() => import('../features/tsrequest/ts/update/index')),
    layout: LayoutDefault,
  },
  {
    menucode: 'newtsrequest',
    auth: 'newtsrequestuserpage',
    path: '/tsrequest/cbol/create',
    component: lazy(() => import('../features/tsrequest/cbol/new/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'updatetsrequestuserpage',
    path: '/tsrequest/cbol/edit/:id',
    component: lazy(() => import('../features/tsrequest/cbol/update/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'updateusertsrequest',
    path: '/usertsrequest/edit/:id',
    component: lazy(() => import('../features/tsrequest/cbol/update/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'pendingtsrequests',
    path: '/tsrequest/todo',
    component: lazy(() => import('../features/tsrequest/todo/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tsrequestreport',
    path: '/tsrequest/report',
    component: lazy(() => import('../features/tsrequest/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tsrequestdetailreport',
    path: '/tsrequestdetail/report',
    component: lazy(() => import('../features/tsrequest/detailreport/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'assigntsrequest',
    path: '/tsrequest/assign/:id',
    component: lazy(() => import('../features/tsrequest/assign/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'processtsrequest',
    path: '/tsrequest/process/:id',
    component: lazy(() => import('../features/tsrequest/process/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tsrequestdetail',
    path: '/tsrequest/detail/:id',
    component: lazy(() => import('../features/tsrequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tsrequestdetailrepostdetail',
    path: '/tsrequestdetail/detail/:id',
    component: lazy(() => import('../features/tsrequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'usertsrequestdetail',
    path: '/usertsrequest/detail/:id',
    component: lazy(() => import('../features/tsrequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tsreplacementrequests',
    path: '/tsreplacementrequest/todo',
    component: lazy(() =>
      import('../features/tsreplacementrequest/todo/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'newtsreplacementrequests',
    path: '/tsreplacementrequest/create/:id',
    component: lazy(() => import('../features/tsreplacementrequest/new/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'updatetsreplacementrequest',
    path: '/tsreplacementrequest/edit/:id',
    component: lazy(() =>
      import('../features/tsreplacementrequest/update/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'tsreplacementrequestdetail',
    path: '/tsreplacementrequest/detail/:id',
    component: lazy(() =>
      import('../features/tsreplacementrequest/detail/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'processtsreplacementrequest',
    path: '/tsreplacementrequest/process/:id',
    component: lazy(() =>
      import('../features/tsreplacementrequest/process/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'tsreplacementreport',
    path: '/tsreplacementrequest/report',
    component: lazy(() =>
      import('../features/tsreplacementrequest/report/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'nocreatesaporderreplacementrequests',
    path: '/replacementrequests/todo',
    component: lazy(() => import('../features/replacementrequest/todo/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'nostartreturntsrequests',
    path: '/tsreturnrequest/unreturn',
    component: lazy(() => import('../features/tsreturnrequest/unreturn/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'newtsreturnrequests',
    path: '/tsreturnrequest/create/:id',
    component: lazy(() => import('../features/tsreturnrequest/new/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'updatetsreturnrequest',
    path: '/tsreturnrequest/edit/:id',
    component: lazy(() => import('../features/tsreturnrequest/update/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tsreturnrequestdetail',
    path: '/tsreturnrequest/detail/:id',
    component: lazy(() => import('../features/tsreturnrequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'processtsreturnrequest',
    path: '/tsreturnrequest/process/:id',
    component: lazy(() => import('../features/tsreturnrequest/process/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'pendingreturnrequests',
    path: '/tsreturnrequest/todo',
    component: lazy(() => import('../features/tsreturnrequest/todo/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tsreturnreport',
    path: '/tsreturnrequest/report',
    component: lazy(() => import('../features/tsreturnrequest/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'replenishlareturnrequest',
    path: '/lareturnrequest/replenish/:id',
    component: lazy(() =>
      import('../features/lareturnrequest/replenish/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'processlareturnrequest',
    path: '/lareturnrequest/process/:id',
    component: lazy(() => import('../features/lareturnrequest/process/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'lareturnrequestdetail',
    path: '/lareturnrequest/detail/:id',
    component: lazy(() => import('../features/lareturnrequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'userlareturnrequestdetail',
    path: '/userlareturnrequest/detail/:id',
    component: lazy(() => import('../features/lareturnrequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'lareturnreport',
    path: '/lareturnrequest/report',
    component: lazy(() => import('../features/lareturnrequest/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'newlarequest',
    path: '/larequest/create',
    component: lazy(() => import('../features/larequest/new/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'updatelarequest',
    path: '/larequest/edit/:id',
    component: lazy(() => import('../features/larequest/update/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'processlarequest',
    path: '/larequest/process/:id',
    component: lazy(() => import('../features/larequest/process/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'larequestdetail',
    path: '/larequest/detail/:id',
    component: lazy(() => import('../features/larequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'wdrequestdetail',
    path: '/larequest/wrongdelivery/:id',
    component: lazy(() => import('../features/larequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'dmrequestdetail',
    path: '/larequest/damage/:id',
    component: lazy(() => import('../features/larequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'nostarttasks',
    path: '/larequest/todo',
    component: lazy(() => import('../features/larequest/todo/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'larequestreport',
    path: '/larequest/report',
    component: lazy(() => import('../features/larequest/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'ongoingtask',
    path: '/larequestdetail/todo',
    component: lazy(() => import('../features/larequestdetail/todo/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'nostartreplacementlarequests',
    path: '/larequestdetail/replenish',
    component: lazy(() =>
      import('../features/larequestdetail/replenish/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'processladetailrequest',
    path: '/larequestdetail/process/:id',
    component: lazy(() => import('../features/larequestdetail/process/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'wdrequestdetail',
    path: '/larequestdetail/wrongdelivery/:id',
    component: lazy(() => import('../features/larequestdetail/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'dmrequestdetail',
    path: '/larequestdetail/damage/:id',
    component: lazy(() => import('../features/larequestdetail/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'larequestitemdetail',
    path: '/larequestdetail/detail/:id',
    component: lazy(() => import('../features/larequestdetail/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'wdrequestreport',
    path: '/larequest/report/wrongdelivery',
    component: lazy(() =>
      import('../features/larequestdetail/report/wrongdelivery/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'dmrequestreport',
    path: '/larequest/report/damage',
    component: lazy(() =>
      import('../features/larequestdetail/report/damage/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'newlareplacementrequests',
    path: '/lareplacementrequest/create/:id',
    component: lazy(() => import('../features/lareplacementrequest/new/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'updatelareplacementrequest',
    path: '/lareplacementrequest/edit/:id',
    component: lazy(() =>
      import('../features/lareplacementrequest/update/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'lareplacementrequestdetail',
    path: '/lareplacementrequest/detail/:id',
    component: lazy(() =>
      import('../features/lareplacementrequest/detail/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'processlareplacementrequest',
    path: '/lareplacementrequest/process/:id',
    component: lazy(() =>
      import('../features/lareplacementrequest/process/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'lareplacementreport',
    path: '/lareplacementrequest/report',
    component: lazy(() =>
      import('../features/lareplacementrequest/report/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'lareplacementrequests',
    path: '/lareplacementrequest/todo',
    component: lazy(() =>
      import('../features/lareplacementrequest/todo/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'pendingfactoryclaims',
    path: '/pendingfactoryclaims',
    component: lazy(() => import('../features/factoryclaims/pending/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'laclaimreport',
    path: '/laclaimreport',
    component: lazy(() => import('../features/factoryclaims/repost/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'laclaimdetail',
    path: '/laclaimreport/detail/:id',
    component: lazy(() => import('../features/factoryclaims/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'processfactoryclaims',
    path: '/pendingfactoryclaims/process/:id',
    component: lazy(() => import('../features/factoryclaims/process/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'pendingonsitecheckrequests',
    path: '/onsitesupport/todo',
    component: lazy(() => import('../features/onsitesupport/todo/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tsonsitesupportreport',
    path: '/TSOnSiteSupportReport',
    component: lazy(() => import('../features/onsitesupport/tsreport/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'onsitesupportreport',
    path: '/onsitesupportreport',
    component: lazy(() => import('../features/onsitesupport/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'onsitesupportreportdetail',
    path: '/onsitesupport/detail/:id',
    component: lazy(() => import('../features/onsitesupport/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'onsitesupportdetail',
    path: '/onsitesupportreport/detail/:id',
    component: lazy(() => import('../features/onsitesupport/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'onsitesupporttsreportdetail',
    path: '/tsonsitesupportreport/detail/:id',
    component: lazy(() => import('../features/onsitesupport/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'onsitesupportstartcheck',
    path: '/onsitesupport/startcheck/:id',
    component: lazy(() => import('../features/onsitesupport/startcheck/V1')),
    layout: LayoutDefault,
  },
  {
    path: '/onsitesupport/startcheck/mobile/:id',
    component: lazy(() => import('../features/onsitesupport/startcheck/V1')),
    anonymous: true,
    layout: false,
  },
  {
    auth: 'onsitesupportclosecheck',
    path: '/onsitesupport/closecheck/:id',
    component: lazy(() => import('../features/onsitesupport/closecheck/V1')),
    layout: LayoutDefault,
  },
  {
    path: '/onsitesupport/closecheck/mobile/:id',
    component: lazy(() => import('../features/onsitesupport/closecheck/V1')),
    anonymous: true,
    layout: false,
  },
  {
    auth: 'pendingonsiteexpenses',
    path: '/pendingonsiteexpenses',
    component: lazy(() => import('../features/onsiteexpenses/pending/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'newonsiteexpenses',
    path: '/pendingonsiteexpenses/new/:spcode/:tsid',
    component: lazy(() => import('../features/onsiteexpenses/new/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'unclaimsupportlist',
    path: '/unclaimsupportlist',
    component: lazy(() =>
      import('../features/onsiteexpenses/unclaimsupportlist/index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'processonsiteexpenses',
    path: '/onsiteexpenses/process/:id',
    component: lazy(() => import('../features/onsiteexpenses/process/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tsonsitesupportexpensereport',
    path: '/tsonsiteSupportexpensereport',
    component: lazy(() => import('../features/onsiteexpenses/repost/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'detailonsiteexpenses',
    path: '/onsiteexpenses/detail/:id',
    component: lazy(() => import('../features/onsiteexpenses/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'masterdata',
    path: '/masterdata',
    component: lazy(() => import('../features/masterdata/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'region',
    path: '/region',
    component: lazy(() => import('../features/region/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'producttypeclassify',
    path: '/producttypeclassify',
    component: lazy(() => import('../features/producttypeclassify/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'producttype',
    path: '/producttype',
    component: lazy(() => import('../features/producttype/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'problemtype',
    path: '/problemtype',
    component: lazy(() => import('../features/problemtype/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'workflow',
    path: '/workflow',
    component: lazy(() => import('../features/workflow/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'workflowchart',
    path: '/workflow/chart/:id',
    component: lazy(() => import('../features/workflowchart/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'workflowchart',
    path: '/workflow/sparrowtemplate/:id',
    component: lazy(() => import('../features/sparrowtemplate/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'emailtemplate',
    path: '/emailtemplate',
    component: lazy(() => import('../features/emailtemplate/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'asynctask',
    path: '/asynctask',
    component: lazy(() => import('../features/asynctask/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'smstemplate',
    path: '/smstemplate',
    component: lazy(() => import('../features/smstemplate/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'spmasterdata',
    path: '/spmasterdata',
    component: lazy(() => import('../features/spmasterdata/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tsreturnsetting',
    path: '/tsreturnsetting',
    component: lazy(() => import('../features/tsreturnsetting/index')),
    layout: LayoutDefault,
  },
  {
    path: '/signin',
    component: lazy(() => import('../features/auth/SignIn')),
    anonymous: true,
    layout: false,
  },
  {
    path: '/singlesignin',
    component: lazy(() => import('../features/auth/SingleSignIn')),
    anonymous: true,
    layout: false,
  },
  {
    path: '/signin-internal-use-only',
    component: lazy(() => import('../features/auth/SignInTest')),
    anonymous: true,
    layout: false,
  },
  {
    auth: 'download',
    path: '/attachment/download/:id',
    component: lazy(() => import('../features/attachment/download')),
    layout: false,
  },
  {
    auth: 'newinternaldeliverydifferencesrequest',
    path: '/internaldeliverydifferencesrequest/create',
    component: lazy(() => import('../features/internaldeliverydifferencesrequest/new/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'updateinternaldeliverydifferencesrequest',
    path: '/internaldeliverydifferencesrequest/edit/:id',
    component: lazy(() => import('../features/internaldeliverydifferencesrequest/update/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'processinternaldeliverydifferencesrequest',
    path: '/internaldeliverydifferencesrequest/process/:id',
    component: lazy(() => import('../features/internaldeliverydifferencesrequest/process/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'processinternaldeliverydifferencesrequestdetail',
    path: '/internaldeliverydifferencesrequest/process/:id',
    component: lazy(() => import('../features/internaldeliverydifferencesrequest/process/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'internaldeliverydifferencesrequestdetail',
    path: '/internaldeliverydifferencesrequest/detail/:id',
    component: lazy(() => import('../features/internaldeliverydifferencesrequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'pendinginternaldeliverydifferencesrequests',
    path: '/internaldeliverydifferencesrequest/todo',
    component: lazy(() => import('../features/internaldeliverydifferencesrequest/todo/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'internaldeliverydifferencesrequestreport',
    path: '/internaldeliverydifferencesrequest/report',
    component: lazy(() => import('../features/internaldeliverydifferencesrequest/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'internaldeliverydifferencesdetailrequestreport',
    path: '/internaldeliverydifferencesdetailrequest/report',
    component: lazy(() => import('../features/internaldeliverydifferencesdetailrequest/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'internaldeliverydifferencesrequestitemdetail',
    path: '/internaldeliverydifferencesrequest/item/detail/:id',
    component: lazy(() => import('../features/internaldeliverydifferencesdetailrequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'newabroaddeliverydifferencesrequest',
    path: '/abroaddeliverydifferencesrequest/create',
    component: lazy(() => import('../features/abroaddeliverydifferencesrequest/new/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'updateabroaddeliverydifferencesrequest',
    path: '/abroaddeliverydifferencesrequest/edit/:id',
    component: lazy(() => import('../features/abroaddeliverydifferencesrequest/update/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'processabroaddeliverydifferencesrequest',
    path: '/abroaddeliverydifferencesrequest/process/:id',
    component: lazy(() => import('../features/abroaddeliverydifferencesrequest/process/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'processabroaddeliverydifferencesrequestdetail',
    path: '/abroaddeliverydifferencesrequest/process/:id',
    component: lazy(() => import('../features/abroaddeliverydifferencesrequest/process/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'abroaddeliverydifferencesrequestdetail',
    path: '/abroaddeliverydifferencesrequest/detail/:id',
    component: lazy(() => import('../features/abroaddeliverydifferencesrequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'pendingabroaddeliverydifferencesrequests',
    path: '/abroaddeliverydifferencesrequest/todo',
    component: lazy(() => import('../features/abroaddeliverydifferencesrequest/todo/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'abroaddeliverydifferencesrequestreport',
    path: '/abroaddeliverydifferencesrequest/report',
    component: lazy(() => import('../features/abroaddeliverydifferencesrequest/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'abroaddeliverydifferencesdetailrequestreport',
    path: '/abroaddeliverydifferencesdetailrequest/report',
    component: lazy(() => import('../features/abroaddeliverydifferencesdetailrequest/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'abroaddeliverydifferencesrequestitemdetail',
    path: '/abroaddeliverydifferencesrequest/item/detail/:id',
    component: lazy(() => import('../features/abroaddeliverydifferencesdetailrequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'newinstoragedeliverydifferencesrequest',
    path: '/instoragedeliverydifferencesrequest/create',
    component: lazy(() => import('../features/instoragedeliverydifferencesrequest/new/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'updateinstoragedeliverydifferencesrequest',
    path: '/instoragedeliverydifferencesrequest/edit/:id',
    component: lazy(() => import('../features/instoragedeliverydifferencesrequest/update/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'processinstoragedeliverydifferencesrequest',
    path: '/instoragedeliverydifferencesrequest/process/:id',
    component: lazy(() => import('../features/instoragedeliverydifferencesrequest/process/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'processinstoragedeliverydifferencesrequestdetail',
    path: '/instoragedeliverydifferencesrequest/process/:id',
    component: lazy(() => import('../features/instoragedeliverydifferencesrequest/process/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'instoragedeliverydifferencesrequestdetail',
    path: '/instoragedeliverydifferencesrequest/detail/:id',
    component: lazy(() => import('../features/instoragedeliverydifferencesrequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'pendinginstoragedeliverydifferencesrequests',
    path: '/instoragedeliverydifferencesrequest/todo',
    component: lazy(() => import('../features/instoragedeliverydifferencesrequest/todo/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'instoragedeliverydifferencesrequestreport',
    path: '/instoragedeliverydifferencesrequest/report',
    component: lazy(() => import('../features/instoragedeliverydifferencesrequest/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'instoragedeliverydifferencesdetailrequestreport',
    path: '/instoragedeliverydifferencesdetailrequest/report',
    component: lazy(() => import('../features/instoragedeliverydifferencesdetailrequest/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'instoragedeliverydifferencesrequestitemdetail',
    path: '/instoragedeliverydifferencesrequest/item/detail/:id',
    component: lazy(() => import('../features/instoragedeliverydifferencesdetailrequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'processinternaldeliverydifferencesclaimrequest',
    path: '/internaldeliverydifferencesclaimrequest/process/:id',
    component: lazy(() => import('../features/internaldeliverydifferencesclaimrequest/process/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'internaldeliverydifferencesclaimrequestdetail',
    path: '/internaldeliverydifferencesclaimrequest/detail/:id',
    component: lazy(() => import('../features/internaldeliverydifferencesclaimrequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'pendinginternaldeliverydifferencesclaimrequests',
    path: '/internaldeliverydifferencesclaimrequest/todo',
    component: lazy(() => import('../features/internaldeliverydifferencesclaimrequest/todo/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'internaldeliverydifferencesclaimrequestreport',
    path: '/internaldeliverydifferencesclaimrequest/report',
    component: lazy(() => import('../features/internaldeliverydifferencesclaimrequest/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'carrier',
    path: '/carrier',
    component: lazy(() => import('../features/carrier/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'deliverydifferencessummaryreport',
    path: '/deliverydifferencessummary/report',
    component: lazy(() => import('../features/deliverydifferencessummary/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'newtssphazardrequest',
    path: '/tssphazard/new',
    component: lazy(() => import('../features/tssphazard/new/new')),
    layout: LayoutDefault,
  },
  {
    auth: 'newtssphazardrequest',
    path: '/tssphazard/mobile/new',
    component: lazy(() => import('../features/tssphazard/new/new')),
    layout: false,
  },
  {
    auth: 'edittssphazardrequest',
    path: '/tssphazard/edit/:id',
    component: lazy(() => import('../features/tssphazard/edit/edit')),
    layout: LayoutDefault,
  },
  {
    auth: 'edittssphazardrequest',
    path: '/tssphazard/mobile/edit/:id',
    component: lazy(() => import('../features/tssphazard/edit/edit')),
    layout: false,
  },
  {
    auth: 'detailtssphazardrequest',
    path: '/tssphazard/detail/:id',
    component: lazy(() => import('../features/tssphazard/detail/detail')),
    layout: LayoutDefault,
  },
  {
    auth: 'detailtssphazardrequest',
    path: '/tssphazard/mobile/detail/:id',
    component: lazy(() => import('../features/tssphazard/detail/detail')),
    layout: false,
  },
  {
    auth: 'tssphazardhome',
    path: '/tssphazard/home',
    component: lazy(() => import('../features/tssphazard/home/home')),
    layout: LayoutDefault,
  },
  {
    auth: 'tssphazardhome',
    path: '/tssphazard/mobile/home',
    component: lazy(() => import('../features/tssphazard/home/home')),
    layout: false,
  },
  {
    auth: 'tssphazardreport',
    path: '/tssphazard/report',
    component: lazy(() => import('../features/tssphazard/report/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tsrepairrequestcreate',
    path: '/tsrepairrequest/create/:id',
    component: lazy(() => import('../features/tsrepairrequest/new/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tsrepairrequestupdate',
    path: '/tsrepairrequest/edit/:id',
    component: lazy(() => import('../features/tsrepairrequest/update/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tsrepairrequestprocess',
    path: '/tsrepairrequest/process/:id',
    component: lazy(() => import('../features/tsrepairrequest/process/index')),
    layout: LayoutDefault,
  },
  
  {
    auth: 'tsrepairrequestdetail',
    path: '/tsrepairrequest/detail/:id',
    component: lazy(() => import('../features/tsrepairrequest/detail/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'pendingtsrepairrequest',
    path: '/tsrepairrequest/todo',
    component: lazy(() => import('../features/tsrepairrequest/todo/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tsrepairreport',
    path: '/tsrepairrequest/report',
    component: lazy(() => import('../features/tsrepairrequest/report/index')),
    layout: LayoutDefault,
  },
];

function standardize(items) {
  for (let item of items) {
    // menu
    if (item.auth && !item.menu) item.menu = item.auth;
    // anonymous
    item.anonymous = item.anonymous === true ? true : false;
    // exact
    if (item.exact === undefined)
      item.exact = !(item.routes && item.routes.length > 0);
    if (item.routes) standardize(item.routes);
  }
}

standardize(routes);

export default routes;
