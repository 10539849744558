import { debugEnable } from '@/utils/utils';
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import authReducer from '../features/auth/authSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
  },
  middleware: getDefaultMiddleware =>
    debugEnable
      ? getDefaultMiddleware().concat(logger)
      : getDefaultMiddleware(),
});
