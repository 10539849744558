import http from './http';

export function getOnsiteSupportStartAsync(Id) {
  let url = '/OnSiteSupport/getOnsiteSupportStart?Id=' + Id;
  return http.post(url);
}
export function StartCheckUpdataAsync(dto) {
  return http.post('/OnSiteSupport/StartCheckUpdata', dto);
}
export function DeleteImagesAsync(imageid) {
  return http.delete('/OnSiteSupport/DeleteImages?id=' + imageid);
}
export function CloseCheckUpdataAsync(dto) {
  return http.post('/OnSiteSupport/CloseCheckUpdata', dto);
}
export function CheckOnSiteSupprotNoAsync(dto) {
  return http.post('/OnSiteSupport/CheckOnSiteSupprotNo', dto);
}
export function getOnsiteSupportCloseAsync(Id) {
  let url = '/OnSiteSupport/getOnsiteSupportClose?Id=' + Id;
  return http.post(url);
}
export function GetPendingOnSiteSupportListAsync(query) {
  return http.post('/OnSiteSupport/GetPendingOnSiteSupportList', query);
}

export function GetOnSiteSupportList(query) {
  return http.post('/OnSiteSupport/GetOnSiteSupportList', query);
}

export function StartCheckApprove(dto) {
  return http.post('/OnSiteSupport/StartCheckApprove', dto);
}

export function StartCheckReject(dto) {
  return http.post('/OnSiteSupport/StartCheckReject', dto);
}

export function HSEStartCheckAsync(dto) {
  return http.post('/OnSiteSupport/HSEStartCheck', dto);
}
export function GetApprovers(id) {
  return http.post('/OnSiteSupport/GetApprovers?id=' + id);
}
export function GetActionPermissions(id) {
  return http.post('/OnSiteSupport/GetActionPermissions?id=' + id);
}
export function GetOnsiteSupportData(id) {
  return http.post('/OnSiteSupport/GetOnsiteSupportData?id=' + id);
}

export function getToDoCount() {
  return http.get('/OnSiteSupport/GetToDoCount');
}

export function GetOnSiteSupportReportList(data) {
  return http.post('/OnSiteSupport/GetOnSiteSupportReportList', data);
}

export function ExportTSReportDataAsync(data) {
  return http.post('/OnSiteSupport/ExportTSReportData', data);
}
export function ExportReportDataAsync(data) {
  return http.post('/OnSiteSupport/ExportReportData', data);
}

export function GetApprovalLogListAsync(id) {
  return http.post('/OnSiteSupport/GetApprovalLogList?id=' + id);
}

export function CancelOnsiteSupport(id) {
  return http.post('/OnSiteSupport/CancelOnsiteSupport?id=' + id);
}
