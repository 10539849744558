const messages = {
  'ph.username': '用户名',
  'ph.password': '密码',
  'ph.validcode': '验证码',
  'ph.pleseSelect': '请选择...',
  'ph.pleseInput': '请输入...',
  'ph.pleseInputEmail': '请输入邮箱...',
  'ph.pleseInputEmails': '请输入邮箱，多个用;隔开...',
  'ph.issubmint': '是否确认提交',
  'ph.issave': '是否保存',
  'ph.pleseInputFinalTechnicalSolution': '请输入最终技术建议...',
  'ph.pleseInputApproveRemark': '请输入审批意见...',
  'ph.onsitesupport.otherrisks': '如带电测试，噪音，辐射等',
  'ph.onsitesupport.isnotnull': '不能为空！',
  'ph.onsitesupport.returned.isnotnull': '退回理由不能为空。',
  'ph.onsitesupport.isdelete': '是否删除？',
  'ph.pleseInputRemark': '请输入备注',
};
export default messages;
