import http from './http';

export function getPagingList(query) {
  return http.get('/AbroadDeliveryDifferences/GetPagingList', query);
}

export function getDraftRequestCount() {
  return http.get('/AbroadDeliveryDifferences/GetDraftRequestCount');
}

export function get(id, type) {
  return http.get('/AbroadDeliveryDifferences/Get?id=' + id + '&type=' + type);
}

export function getPlanSolution(id) {
  return http.get('/AbroadDeliveryDifferences/GetPlanSolution?id=' + id);
}

export function getByDetailId(id) {
  return http.get('/AbroadDeliveryDifferences/GetByDetailId?id=' + id);
}

export function getBasicInfo() {
  return http.get('/AbroadDeliveryDifferences/GetBasicInfo');
}

export function getDefaultSLocByBin(bin) {
  return http.get('/AbroadDeliveryDifferences/GetDefaultSLocByBin?bin=' + bin);
}

export function getToDoCount() {
  return http.get('/AbroadDeliveryDifferences/GetToDoCount');
}

export function save(data) {
  return http.post('/AbroadDeliveryDifferences/Save', data);
}

export function update(data) {
  return http.post('/AbroadDeliveryDifferences/Update', data);
}

export function submit(data) {
  return http.post('/AbroadDeliveryDifferences/Submit', data);
}

export function del(id) {
  return http.delete('/AbroadDeliveryDifferences/Delete?id=' + id);
}

export function customHandling(data) {
  return http.post('/AbroadDeliveryDifferences/CustomHandling', data);
}

export function saveCustomHandling(data) {
  return http.post('/AbroadDeliveryDifferences/SaveCustomHandling', data);
}

export function buyerHandling(data) {
  return http.post('/AbroadDeliveryDifferences/BuyerHandling', data);
}

export function saveBuyerHandling(data) {
  return http.post('/AbroadDeliveryDifferences/SaveBuyerHandling', data);
}

export function warehouseHandling(data) {
  return http.post('/AbroadDeliveryDifferences/WarehouseHandling', data);
}

export function saveWarehouseHandling(data) {
  return http.post('/AbroadDeliveryDifferences/SaveWarehouseHandling', data);
}

export function warehouseConfirmSubmit(data) {
  return http.post('/AbroadDeliveryDifferences/WarehouseConfirmSubmit', data);
}

export function reSubmit(data) {
  return http.post('/AbroadDeliveryDifferences/ReSubmit', data);
}

export function standardReturn(data) {
  return http.post('/AbroadDeliveryDifferences/StandardReturn', data);
}

export function standardReject(data) {
  return http.post('/AbroadDeliveryDifferences/StandardReject', data);
}

export function standardApproval(data) {
  return http.post('/AbroadDeliveryDifferences/StandardApproval', data);
}

export function savePlanSolution(data) {
  return http.post('/AbroadDeliveryDifferences/SavePlanSolution', data);
}

export function getToDoPagingList(query) {
  return http.post('/AbroadDeliveryDifferences/GetToDoPagingList', query);
}

export function getDetailReportPagingList(query) {
  return http.post('/AbroadDeliveryDifferences/GetDetailReportPagingList', query);
}

export function exportDetailReportData(query) {
  return http.post('/AbroadDeliveryDifferences/ExportDetailReportData', query);
}

export function getReportPagingList(query) {
  return http.post('/AbroadDeliveryDifferences/GetReportPagingList', query);
}

export function getDraftPagingList(query) {
  return http.post('/AbroadDeliveryDifferences/GetDraftPagingList', query);
}

export function exportReportData(query) {
  return http.post('/AbroadDeliveryDifferences/ExportReportData', query);
}

