import http from './http';

export function getPagingList(query) {
  return http.get('/InternalDeliveryDifferences/GetPagingList', query);
}

export function getDraftRequestCount() {
  return http.get('/InternalDeliveryDifferences/GetDraftRequestCount');
}

export function get(id, type) {
  return http.get('/InternalDeliveryDifferences/Get?id=' + id + '&type=' + type);
}

export function getBasicData(id) {
  return http.get('/InternalDeliveryDifferences/GetBasicData?id=' + id);
}

export function getByDetailId(id) {
  return http.get('/InternalDeliveryDifferences/GetByDetailId?id=' + id);
}

export function getPlanSolution(id) {
  return http.get('/InternalDeliveryDifferences/GetPlanSolution?id=' + id);
}

export function getTSRequestData(id) {
  return http.get('/InternalDeliveryDifferences/GetTSRequestData?id=' + id);
}

export function getBasicInfo() {
  return http.get('/InternalDeliveryDifferences/GetBasicInfo');
}

export function getToDoCount() {
  return http.get('/InternalDeliveryDifferences/GetToDoCount');
}

export function save(data) {
  return http.post('/InternalDeliveryDifferences/Save', data);
}

export function update(data) {
  return http.post('/InternalDeliveryDifferences/Update', data);
}

export function submit(data) {
  return http.post('/InternalDeliveryDifferences/Submit', data);
}

export function del(id) {
  return http.delete('/InternalDeliveryDifferences/Delete?id=' + id);
}

export function tmcHandling(data) {
  return http.post('/InternalDeliveryDifferences/TMCHandling', data);
}

export function saveTMCHandling(data) {
  return http.post('/InternalDeliveryDifferences/SaveTMCHandling', data);
}

export function buyerHandling(data) {
  return http.post('/InternalDeliveryDifferences/BuyerHandling', data);
}

export function saveBuyerHandling(data) {
  return http.post('/InternalDeliveryDifferences/SaveBuyerHandling', data);
}

export function buyerConfirm(data) {
  return http.post('/InternalDeliveryDifferences/BuyerConfirm', data);
}

export function saveBuyerConfirm(data) {
  return http.post('/InternalDeliveryDifferences/SaveBuyerConfirm', data);
}

export function warehouseConfirm(data) {
  return http.post('/InternalDeliveryDifferences/WarehouseConfirm', data);
}

export function reSubmit(data) {
  return http.post('/InternalDeliveryDifferences/ReSubmit', data);
}

export function standardReturn(data) {
  return http.post('/InternalDeliveryDifferences/StandardReturn', data);
}

export function standardApproval(data) {
  return http.post('/InternalDeliveryDifferences/StandardApproval', data);
}

export function standardReject(data) {
  return http.post('/InternalDeliveryDifferences/StandardReject', data);
}

export function savePlanSolution(data) {
  return http.post('/InternalDeliveryDifferences/SavePlanSolution', data);
}

export function getToDoPagingList(query) {
  return http.post('/InternalDeliveryDifferences/GetToDoPagingList', query);
}

export function getDetailReportPagingList(query) {
  return http.post('/InternalDeliveryDifferences/GetDetailReportPagingList', query);
}

export function exportDetailReportData(query) {
  return http.post('/InternalDeliveryDifferences/ExportDetailReportData', query);
}

export function getReportPagingList(query) {
  return http.post('/InternalDeliveryDifferences/GetReportPagingList', query);
}

export function getDraftPagingList(query) {
  return http.post('/InternalDeliveryDifferences/GetDraftPagingList', query);
}

export function exportReportData(query) {
  return http.post('/InternalDeliveryDifferences/ExportReportData', query);
}

