import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
// antd & dayjs
import { ConfigProvider } from 'antd';
import antdENUS from 'antd/lib/locale/en_US';
import antdZHCN from 'antd/lib/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
// app
//import { getLocale } from './locales';
import enUS from './locales/en-US';
import zhCN from './locales/zh-CN';
import AppRouter from './app/router';
import './App.less';

const locale = 'zh-CN'; //getLocale();
const isZHCN = locale === 'zh-CN';
const messages = isZHCN ? zhCN : enUS;
const antdLocale = isZHCN ? antdZHCN : antdENUS;

function App() {
  const detectZoom = () => {
    console.log('ratio:' + window.devicePixelRatio);
    let ratio = 0,
      screen = window.screen,
      ua = navigator.userAgent.toLowerCase();
    if (window.devicePixelRatio !== undefined) {
      ratio = window.devicePixelRatio;
    } else if (~ua.indexOf('msie')) {
      if (screen.deviceXDPI && screen.logicalXDPI) {
        ratio = screen.deviceXDPI / screen.logicalXDPI;
      }
    } else if (
      window.outerWidth !== undefined &&
      window.innerWidth !== undefined
    ) {
      ratio = window.outerWidth / window.innerWidth;
    }
    if (ratio) {
      ratio = Math.round(ratio * 100);
    }
    return ratio;
  };
  //数据大屏自适应函数
  const handleScreenAuto = () => {
    // const t = window.devicePixelRatio;
    // const width_screen = window.screen.width;
    // const height_screen = window.screen.height;
    // console.log(t + '|' + width_screen + '|' + height_screen);
    // let zoom = 1;
    // if (t !== 1) {
    //   zoom = (1 / t) * zoom;
    // }
    const m = detectZoom();
    console.log('Zoom:' + m);
    if (window.screen.width * window.devicePixelRatio >= 3840) {
      //4k屏
      console.log('4k');
      //document.getElementById('customBody').style.zoom = 100 / (Number(m) / 2);
      if (window.devicePixelRatio === 1) {
        document.getElementById('customBody').style.zoom = 1.3;
      }
    } else {
      console.log('un4k');
      var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
      console.log('is Mac：' + isMac);
      if (!isMac) {
        document.getElementById('customBody').style.zoom = 100 / Number(m);
      } else {
        document.getElementById('customBody').style.zoom = 0.8;
      }
    }
  };
  useEffect(() => {
    // document.title = messages['layout.appname'];
    // dayjs.locale(isZHCN ? 'zh-cn' : 'en');
    if (!/mobile/i.test(navigator.userAgent)) {
      //初始化自适应  ----在刚显示的时候就开始适配一次
      handleScreenAuto();
      //绑定自适应函数   ---防止浏览器栏变化后不再适配
      window.onresize = () => handleScreenAuto();
      //退出大屏后自适应消失   ---这是react的组件销毁生命周期，如果你是vue则写在deleted中。最好在退出大屏的时候接触自适应
      return () => (window.onresize = null);
    }
  }, []);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <ConfigProvider componentSize="middle" locale={antdLocale}>
        <AppRouter />
      </ConfigProvider>
    </IntlProvider>
  );
}

export default App;
